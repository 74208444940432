import { Grid } from '@mui/material';
import QuizIcon from '@mui/icons-material/Quiz';
import Wrapper from 'components/Card/Wrapper';
import langString from 'utils/langString';
import QuizesTable from './Table';
import { useEffect, useState } from 'react';
import axiosServices from 'utils/axiosServices';
import { makeFormData, requestUrl } from 'utils/Helpers';
import { useSelector, useDispatch } from 'react-redux';
import { DefaultRootStateProps } from 'types';
import { SNACKBAR_OPEN } from 'store/actions';
import { QuizDeployParamType } from './QuizPublish';

export default function SavedQuizes() {
    const [loading, setLoading] = useState(false);
    const course = useSelector((state: DefaultRootStateProps) => state.courseData);
    const [topic, setTopic] = useState<string[]>([]);
    const [difficulty, setDifficulty] = useState(0);
    const [search, setSearch] = useState('');
    const [quizes, setQuizes] = useState<any[]>([]);
    const dispatch = useDispatch();

    const quizDeploy = async (data: QuizDeployParamType) => {
        setLoading(true);
        try {
            const response = await axiosServices.post(
                requestUrl('ace_exam', 'publish_quiz'),
                makeFormData([
                    { key: 'course_id', value: course?.id },
                    { key: 'quiz_id', value: data.id },
                    { key: 'batch', value: data.batches },
                    { key: 'notifications', value: data.notifications },
                    { key: 'access_code', value: data.code },
                    { key: 'quiz_type', value: data.quizType },
                    { key: 'view_type', value: data.viewType },
                    { key: 'quizAttemptType', value: data.quizAttemptType },
                    { key: 'question_set', value: data.questionSet },
                    { key: 'time_open', value: data.timeOpen },
                    { key: 'time_close', value: data.timeClose },
                    { key: 'deadline', value: data.deadline },
                    { key: 'time_limit', value: data.timeLimit }
                ])
            );
            if (response.data?.success) {
                getQuizes();
                setLoading(false);
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: response.data.msg,
                    variant: 'alert',
                    alertSeverity: 'success'
                });
            } else {
                setLoading(false);
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: response.data.msg,
                    variant: 'alert',
                    alertSeverity: 'error'
                });
            }
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    };

    const quizPrint = (id: number) => console.log('printed', id);

    const quizDelete = async (id: number) => {
        setLoading(true);
        try {
            const response = await axiosServices.post(requestUrl('ace_exam', 'delete_quiz'), makeFormData([{ key: 'quiz_id', value: id }]));
            if (response.data.success) {
                if (response.data?.response) {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: response.data.msg,
                        variant: 'alert',
                        alertSeverity: 'success'
                    });
                }
            } else {
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: response.data.msg,
                    variant: 'alert',
                    alertSeverity: 'error'
                });
            }
            setLoading(false);
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    };

    const quizParams = (courseID: number | string, searchKey: string, difficultyID: number, topics: string[]) =>
        makeFormData([
            { key: 'course_id', value: courseID },
            { key: 'search', value: searchKey },
            { key: 'difficulty', value: difficultyID },
            { key: 'topics', value: topics }
        ]);

    const getQuizes = async () => {
        setLoading(true);
        try {
            const response = await axiosServices.post(requestUrl('ace_exam', 'quizes'), quizParams(course?.id, search, difficulty, topic));
            if (response.status === 200 && response.data.success) {
                if (response.data?.response) {
                    setQuizes(response.data.response);
                    setLoading(false);
                }
            }
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    };

    useEffect(() => {
        getQuizes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [course, difficulty, search, topic]);

    return (
        <Wrapper title={langString('savedquizes')} icon={<QuizIcon />} loading={loading}>
            <Grid container>
                <Grid item sm={12} md={12}>
                    <QuizesTable
                        deploy={(data: QuizDeployParamType) => quizDeploy(data)}
                        print={(id: number) => quizPrint(id)}
                        deleteQuiz={(id: number) => quizDelete(id)}
                        course={course}
                        topic={topic}
                        setTopic={(value) => setTopic(value)}
                        difficulty={difficulty}
                        setDifficulty={(value) => setDifficulty(value)}
                        search={search}
                        setSearch={(value) => setSearch(value)}
                        quizes={quizes}
                        setQuizes={(value) => setQuizes(value)}
                    />
                </Grid>
            </Grid>
        </Wrapper>
    );
}

import { Grid, FormControl, InputLabel, MenuItem, Select, TextField, Typography, Button } from '@mui/material';
import { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import langString from 'utils/langString';
import axiosServices from 'utils/axiosServices';
import { makeFormData, requestUrl } from 'utils/Helpers';
import { useDispatch } from 'react-redux';
import { ADD_ALL_QUESTIONS, SNACKBAR_OPEN } from 'store/actions';

type AutoGenerateQuizPropsType = {
    courseID: number | string;
    categoryID: number | string | undefined;
    setOpen: (value: boolean) => void;
    setQuestions: (value: string) => void;
    setSelectedIDs: (value: any) => void;
    categoryName: string | undefined;
};
export default function AutoGenerateQuiz({
    setQuestions,
    courseID,
    categoryID,
    setOpen,
    setSelectedIDs,
    categoryName
}: AutoGenerateQuizPropsType) {
    const [topicList, setTopicList] = useState<any[]>([]);
    const [topics, setTopics] = useState<string[]>([]);
    const [subCategory, setSubCategory] = useState(0);
    const [difficulties, setDifficulties] = useState<any[]>([]);
    const [randomNumber, setRandomNumber] = useState(1);
    const [difficulty, setDifficulty] = useState(0);
    const [submitting, setSubmitting] = useState(false);
    const dispatch = useDispatch();

    const topicChange = (event: any, value: any) => {
        if (value) {
            const topicIDs = value.map((item: any) => item.id);
            setTopics(topicIDs);
        }
    };

    const handleGenerate = () => {
        setSubmitting(true);
        const init = async () => {
            try {
                const response = await axiosServices.post(
                    requestUrl('ace_exam', 'question_bank'),
                    makeFormData([
                        { key: 'course_id', value: courseID && courseID },
                        { key: 'difficulty', value: difficulty },
                        { key: 'sub_category_type', value: subCategory },
                        { key: 'topics', value: topics },
                        { key: 'random_question', value: randomNumber }
                    ])
                );
                if (response.status === 200 && response.data.success) {
                    setQuestions(response.data.response.data);
                    dispatch({
                        type: ADD_ALL_QUESTIONS,
                        allQuestions: response.data.response.data
                    });
                    const allSelectedID: number[] = [];
                    response.data.response.data.forEach((item: any) => {
                        // allSelectedQuestions.push(item);
                        allSelectedID.push(item.id);
                    });
                    setSelectedIDs(allSelectedID);
                    setOpen(false);
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: 'Random question generated',
                        variant: 'alert',
                        alertSeverity: 'success'
                    });
                } else {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: response.data.msg,
                        variant: 'alert',
                        alertSeverity: 'error'
                    });
                }
            } catch (e) {
                console.log(e);
            }
        };
        init();
        setSubmitting(false);
    };

    useEffect(() => {
        const init = async () => {
            try {
                const response = await axiosServices.post(
                    requestUrl('ace_exam', 'get_topics'),
                    makeFormData([
                        { key: 'course_id', value: courseID || 0 },
                        { key: 'category_id', value: categoryID || 0 }
                    ])
                );
                if (response.status === 200 && response.data.success) {
                    if (response.data?.response) {
                        setTopicList(response.data.response);
                    }
                }
            } catch (e) {
                console.log(e);
            }
        };
        init();
    }, [categoryID, courseID]);

    useEffect(() => {
        const init = async () => {
            try {
                const response = await axiosServices.post(requestUrl('ace_exam', 'get_dificulties'));
                if (response.status === 200 && response.data.success) {
                    if (response.data?.response) {
                        setDifficulties(response.data.response);
                    }
                }
            } catch (e) {
                console.log(e);
            }
        };
        init();
    }, []);

    return (
        <Grid container spacing={4} direction="column" justifyContent="center" alignItems="center">
            <Grid item sx={{ width: '100%' }}>
                <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ width: '100%' }}>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <Autocomplete
                                size="small"
                                multiple
                                id="multiple-limit-tags"
                                options={topicList}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                    <TextField {...params} label={langString('topics')} placeholder={langString('topics')} />
                                )}
                                onChange={(event: any, value: any) => topicChange(event, value)}
                            />
                        </FormControl>
                    </Grid>
                    {categoryName && categoryName === 'Cambridge O-Level' && (
                        <Grid item xs={4}>
                            <TextField
                                select
                                size="small"
                                id="outlined-basic"
                                label={langString('subcategory')}
                                variant="outlined"
                                fullWidth
                                value={subCategory}
                                onChange={(event: any) => setSubCategory(event.target.value)}
                            >
                                <MenuItem value={0}>
                                    {langString('select')} {langString('subcategory')}
                                </MenuItem>
                                <MenuItem value={1}>{langString('gce')}</MenuItem>
                                <MenuItem value={2}>{langString('igcse')}</MenuItem>
                            </TextField>
                        </Grid>
                    )}

                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <InputLabel id="difficulty-select-label">{langString('difficulty')}</InputLabel>
                            <Select
                                size="small"
                                name="difficultys"
                                labelId="difficulty-select-label"
                                id="difficulty-select"
                                value={difficulty}
                                label={langString('difficulty')}
                                onChange={(event: any) => setDifficulty(event.target.value)}
                            >
                                <MenuItem value={0}>
                                    {langString('select')} {langString('difficulty')}
                                </MenuItem>
                                {difficulties &&
                                    difficulties.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.name?.toUpperCase()}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item>
                        <Typography variant="h5">Numbers of questions you want to add</Typography>
                    </Grid>
                    <Grid item>
                        <TextField
                            size="small"
                            type="number"
                            id="random-question"
                            value={randomNumber}
                            label=""
                            variant="outlined"
                            sx={{ maxWidth: '30%' }}
                            InputProps={{
                                inputProps: { min: 1, pattern: '[0-9]*' }
                            }}
                            onChange={(event: any) => setRandomNumber(Math.abs(event.target.value))}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" onClick={handleGenerate} disabled={submitting}>
                    {langString('generatenow')}
                </Button>
            </Grid>
        </Grid>
    );
}

import React from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    IconButton,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
    Tooltip
} from '@mui/material';
import langString from 'utils/langString';

type ConfirmButtonPropsType = {
    icon?: React.ReactNode;
    title?: string;
    subTitle?: string;
    color?: 'error' | 'success' | 'default' | 'primary' | 'secondary';
    confirmed: () => void;
};
export default function ConfirmButton({ icon, title, subTitle, color, confirmed }: ConfirmButtonPropsType) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <Tooltip title={title || langString('delete')}>
                <IconButton color={color || 'inherit'} onClick={() => setOpen(true)}>
                    {icon || langString('delete')}
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{ p: 3 }}
            >
                <DialogTitle id="alert-dialog-title">{title || 'Are you sure?'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography variant="body2" component="span">
                            {subTitle && subTitle}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ pr: 2.5 }}>
                    <Button
                        sx={{
                            color: theme.palette.error.dark,
                            borderColor: theme.palette.error.dark
                        }}
                        onClick={() => setOpen(false)}
                        color="secondary"
                    >
                        {langString('cancel')}
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                            confirmed();
                            setOpen(false);
                        }}
                        autoFocus
                    >
                        {langString('confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

import { Box, Grid, Button, FormControl, FormHelperText, TextField, IconButton } from '@mui/material';
import langString from 'utils/langString';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ScheduleInput from 'components/common/ScheduleInput';

type BatchFormPropsType = {
    name: string;
    setName: (value: string) => void;
    enrollKey: string;
    setEnrollKey: (value: string) => void;
    schedules: string[];
    setSchedules: (values: string[]) => void;
    capacity: number;
    setCapacity: (value: number) => void;
    saveData: () => void;
    submitting: boolean;
    errors: any;
};
export default function BatchForm({
    name,
    setName,
    schedules,
    setSchedules,
    capacity,
    setCapacity,
    enrollKey,
    setEnrollKey,
    saveData,
    submitting,
    errors
}: BatchFormPropsType) {
    const updateSchedules = (value: string, index: number) => {
        const allSchedules = [...schedules];
        if (value !== 'select 0-0' && !allSchedules.includes(value)) {
            allSchedules[index] = value;
            setSchedules(allSchedules);
        }
    };

    const addSchedule = () => {
        const allSchedules = [...schedules];
        allSchedules.push('select 0-0');
        setSchedules(allSchedules);
    };

    const removeSchedule = (index: number) => {
        const allSchedules = [...schedules];
        if (allSchedules.length > 1) {
            if (index > -1) {
                allSchedules.splice(index, 1);
            }
            setSchedules(allSchedules);
        }
    };

    return (
        <Box>
            <FormControl sx={{ marginBottom: 3 }} fullWidth error={Boolean(errors && errors.name?.required)}>
                <TextField
                    id="outlined-name"
                    value={name}
                    autoFocus
                    name="name"
                    onChange={(event: any) => setName(event.target.value)}
                    label={langString('name')}
                    placeholder={langString('name')}
                    inputProps={{}}
                />
                {errors && errors.name?.required && (
                    <FormHelperText error id="helper-text-name">
                        {errors.name?.message}
                    </FormHelperText>
                )}
            </FormControl>
            <Box>
                {schedules &&
                    schedules.map((item, index: number) => (
                        <Grid container spacing={2}>
                            <Grid item sm={9}>
                                <ScheduleInput
                                    schedule={item}
                                    error={errors?.schedules}
                                    updateSchedule={(value: string) => updateSchedules(value, index)}
                                />
                            </Grid>
                            <Grid item sm={3}>
                                <Box sx={{ marginTop: 2 }}>
                                    <IconButton onClick={() => addSchedule()}>
                                        <AddCircleIcon />
                                    </IconButton>
                                    {index !== 0 && (
                                        <IconButton onClick={() => removeSchedule(index)}>
                                            <RemoveCircleIcon />
                                        </IconButton>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    ))}
            </Box>
            <FormControl sx={{ marginBottom: 3 }} fullWidth error={Boolean(errors && errors.capacity?.required)}>
                <TextField
                    id="outlined-capacity"
                    value={capacity}
                    type="number"
                    name="capacity"
                    onChange={(event: any) => setCapacity(event.target.value)}
                    label={langString('capacity')}
                    placeholder={langString('capacity')}
                    inputProps={{}}
                />
                {errors && errors.capacity?.required && (
                    <FormHelperText error id="helper-text-capacity">
                        {errors.capacity?.message}
                    </FormHelperText>
                )}
            </FormControl>
            {/* <FormControl sx={{ marginBottom: 3 }} fullWidth error={Boolean(errors && errors.enrollKey?.required)}>
                <TextField
                    id="outlined-enrollKey"
                    value={enrollKey}
                    name="enrollKey"
                    onChange={(event: any) => setEnrollKey(event.target.value)}
                    label={langString('enrollKey')}
                    placeholder={langString('enrollKey')}
                    inputProps={{}}
                />
                {errors && errors.enrollKey?.required && (
                    <FormHelperText error id="helper-text-enrollKey">
                        {errors.enrollKey?.message}
                    </FormHelperText>
                )}
            </FormControl> */}
            <FormControl sx={{ margin: 1 }}>
                <Button variant="contained" color="primary" onClick={saveData} disabled={submitting}>
                    {langString('save')}
                </Button>
            </FormControl>
        </Box>
    );
}

import { useState, useEffect } from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Wrapper from 'components/Card/Wrapper';
import { useSelector } from 'react-redux';
import { DefaultRootStateProps } from 'types';
import { SnackBarType } from 'types/common';
import axiosServices from 'utils/axiosServices';
import { makeFormData, requestUrl } from 'utils/Helpers';
import langString from 'utils/langString';
import DashboardCourses from './Courses';
import DashboardRoutine from './Routine';
import DashboardChartAllQuiz from './Charts/AllQuiz';
import DashboardChartAllStudents from './Charts/AllStudents';
import DashboardChartAllStudentMarks from './Charts/AllStudentMarks';
import DashboardChartGroup from './Charts/Group';
import DashboardChartGroupQuiz from './Charts/GroupQuiz';
import DashboardChartTopicReport from './Charts/TopicReport';

export default function Dashboard() {
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState<SnackBarType>({ open: false, message: '' });
    const [courses, setCourses] = useState<any[]>([]);
    const [batches, setBatches] = useState<any[]>([]);

    const course = useSelector((state: DefaultRootStateProps) => state.courseData);

    useEffect(() => {
        const init = async () => {
            setLoading(true);
            try {
                const response = await axiosServices.get(requestUrl('ace_exam', 'teacher_courses'));
                if (response.data.success) {
                    if (response.data?.response) {
                        if (course?.categoryID) {
                            const catCourses = response.data?.response.filter((item: any) => item.category_id === course?.categoryID);
                            setCourses(catCourses);
                        } else {
                            setCourses(response.data.response);
                        }
                    }
                    setLoading(false);
                }
            } catch (e) {
                console.log(e);
                setLoading(false);
                setSnackbar({ open: true, message: langString('servererror') });
            }
        };
        if (course) {
            init();
        } else {
            setSnackbar({ open: true, message: langString('coursenotselected') });
        }
    }, [course]);

    useEffect(() => {
        const init = async () => {
            try {
                const response = await axiosServices.post(
                    requestUrl('ace_exam', 'get_batches'),
                    makeFormData([{ key: 'course_id', value: course?.id }])
                );
                if (response.data.success) {
                    if (response.data?.response && response.data?.response?.length) {
                        setBatches(response.data.response);
                    }
                }
            } catch (e) {
                console.log(e);
            }
        };
        if (course) {
            init();
        }
    }, [course]);

    return (
        <Wrapper
            title={langString('dashboard')}
            icon={<DashboardIcon />}
            snakbar={snackbar}
            snackbarClose={() => setSnackbar({ open: false, message: '' })}
            loading={loading}
        >
            <DashboardCourses courses={courses} selectedID={course?.id} />
            <DashboardRoutine />
            <DashboardChartAllQuiz batches={batches} />
            <DashboardChartAllStudents batches={batches} />
            <DashboardChartAllStudentMarks batches={batches} />
            <DashboardChartGroup batches={batches} />
            <DashboardChartGroupQuiz batches={batches} />
            <DashboardChartTopicReport />
        </Wrapper>
    );
}

import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import langString from 'utils/langString';
import QuizCreateForm from './Form';
import Question from '../QuestionBank/Question';
import axiosServices from 'utils/axiosServices';
import { makeFormData, moveInArray, requestUrl } from 'utils/Helpers';
import { useSelector, useDispatch } from 'react-redux';
import { DefaultRootStateProps } from 'types';
import { SNACKBAR_OPEN } from 'store/actions';
import FormValidators from 'utils/FormValidators';

type ManageQuizPropsType = {
    action: string;
    quizID?: number;
    questions: any[];
    setQuestions: (value: any[]) => void;
    removeQuestion: (value: any) => void;
    resetSelections: () => void;
};
export default function ManageQuiz({ action, quizID, questions, setQuestions, removeQuestion, resetSelections }: ManageQuizPropsType) {
    const [topic, setTopic] = useState<any[]>([]);
    const [difficulty, setDifficulty] = useState(0);
    const [name, setName] = useState('');
    const [errors, setErrors] = useState<any>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const course = useSelector((state: DefaultRootStateProps) => state.courseData);
    const dispatch = useDispatch();

    const questionIDs = questions.map((question) => question.id);
    const topicIDs = topic.map((item: any) => item.id);
    const saveQuiz = async () => {
        if (course && course.id) {
            setIsSubmitting(true);
            const allErrors = FormValidators(['name', 'difficulty'], [name, difficulty]);
            if (allErrors.valid && questions.length) {
                setErrors(null);
                let reqUrl = requestUrl('ace_exam', 'add_quiz');
                let formData = makeFormData([
                    { key: 'name', value: name },
                    { key: 'difficulty', value: difficulty },
                    { key: 'topics', value: topicIDs },
                    { key: 'course_id', value: course?.id },
                    { key: 'question_ids', value: questionIDs }
                ]);
                if (action === 'edit' && quizID) {
                    reqUrl = requestUrl('ace_exam', 'edit_quiz');
                    formData = makeFormData([
                        { key: 'quiz_id', value: quizID },
                        { key: 'name', value: name },
                        { key: 'difficulty', value: difficulty },
                        { key: 'topics', value: topicIDs },
                        { key: 'question_ids', value: questionIDs }
                    ]);
                }
                try {
                    const response = await axiosServices.post(reqUrl, formData);
                    if (response.data.success) {
                        if (action === 'create' && !quizID) {
                            setTopic([]);
                            setDifficulty(0);
                            setName('');
                            setQuestions([]);
                            resetSelections();
                        }
                        dispatch({
                            type: SNACKBAR_OPEN,
                            open: true,
                            message: response.data.msg,
                            variant: 'alert',
                            alertSeverity: 'success'
                        });
                    } else {
                        dispatch({
                            type: SNACKBAR_OPEN,
                            open: true,
                            message: response.data.msg,
                            variant: 'alert',
                            alertSeverity: 'error'
                        });
                    }
                } catch (e) {
                    console.log(e);
                }
            } else {
                setErrors(allErrors);
            }
            setIsSubmitting(false);
        } else {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'Course Not Selected',
                variant: 'alert',
                alertSeverity: 'error'
            });
        }
    };

    const reorderQuestions = (from: number, to: number) => {
        const allQuestions = [...questions];
        const reordered: any[] = moveInArray(allQuestions, from, to);
        if (reordered) {
            setQuestions(reordered);
        }
    };

    useEffect(() => {
        const init = async () => {
            try {
                const response = await axiosServices.post(
                    requestUrl('ace_exam', 'get_quiz_details'),
                    makeFormData([{ key: 'quiz_id', value: quizID || 0 }])
                );
                if (response.data.success) {
                    if (response.data?.response) {
                        setName(response.data.response?.details?.name);
                        setTopic(response.data.response?.topic);
                        setDifficulty(response.data.response?.difficulty?.id);
                    }
                }
            } catch (e) {
                console.log(e);
            }
        };
        if (action === 'edit' && quizID) {
            init();
        } else {
            setName('');
            setTopic([]);
            setDifficulty(0);
        }
    }, [action, quizID]);

    return (
        <Box sx={{ padding: 1, border: '1px solid #EEE' }}>
            <Typography sx={{ fontSize: 15, fontWeight: 600, marginBottom: 3 }}>
                {langString(action)} {langString('quiz')}
            </Typography>
            <QuizCreateForm
                topic={topic}
                setTopic={(value) => setTopic(value)}
                difficulty={difficulty}
                setDifficulty={(value) => setDifficulty(value)}
                name={name}
                setName={(value) => setName(value)}
                save={() => saveQuiz()}
                errors={errors}
                submitting={isSubmitting}
                selectedQuestions={questions}
            />
            <Box sx={{ marginTop: 2 }}>
                {questions.length ? (
                    questions.map((que, key: number) => (
                        <Question
                            key={que.id}
                            serial={key}
                            selected
                            updateSerial={(from: number, to: number) => reorderQuestions(from, to)}
                            totalQuestions={questions.length}
                            name={que.name}
                            options={que.answers}
                            description={que.questiontext}
                            slot="form"
                            actionUpdate={(status: boolean) => !status && removeQuestion(que)}
                        />
                    ))
                ) : (
                    <Typography variant="h4" marginY={5} textAlign="center">
                        {langString('noquestionselected')}
                    </Typography>
                )}
            </Box>
        </Box>
    );
}

import React, { createContext, useEffect, useReducer } from 'react';
import { COURSE_SELECT, LOGIN, LOGOUT, USER_UPDATE } from 'store/actions';
import accountReducer from 'store/accountReducer';
import Loader from 'ui-component/Loader';
import axios from 'axios';
import axiosServices from 'utils/axiosServices';
import { initialLoginContextProps } from 'types';
import { JWTContextType, StudentAttributeType } from 'types/auth';
import { BASEURL, MOODLE_SERVICE, SERVICE_NAME, PANEL } from 'utils/Constants';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { removeCourseInfo } from 'utils/Helpers';

// constant
const initialState: initialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const setSession = (serviceToken?: string | null) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
    } else {
        localStorage.removeItem('serviceToken');
        removeCourseInfo();
        delete axios.defaults.headers.common.Authorization;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const navigate = useNavigate();
    const dispatchCourse = useDispatch();

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem('serviceToken');
                if (serviceToken) {
                    setSession(serviceToken);
                    await axios
                        .get(`${BASEURL}/user/data_service.php?service=get_user_profile`, {
                            headers: {
                                Accept: '*/*',
                                Authorization: serviceToken
                            }
                        })
                        .then((response) => {
                            if (response.data.success === true) {
                                dispatch({
                                    type: LOGIN,
                                    payload: {
                                        isLoggedIn: true,
                                        user: {
                                            id: response.data.response.userid,
                                            username: response.data.response.username,
                                            name: `${response.data.response.firstname} ${response.data.response.lastname}`,
                                            firstname: response.data.response.firstname,
                                            lastname: response.data.response.lastname,
                                            age: response.data.response.age,
                                            gender: response.data.response.gender,
                                            email: response.data.response.email,
                                            location: response.data.response.address,
                                            phone: response.data.response.phone,
                                            badges: response.data.response.badges,
                                            nextBadge: response.data.response.nextbadge,
                                            profile_pic: response.data.response.profile_pic,
                                            division: response.data.response.division,
                                            district: response.data.response.district,
                                            upazila: response.data.response.upazila,
                                            union: response.data.response.union
                                        }
                                    }
                                });
                            } else {
                                dispatch({
                                    type: LOGOUT
                                });
                            }
                        })
                        .catch((error) => {
                            dispatch({
                                type: LOGOUT
                            });
                        });
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
    }, []);

    useEffect(() => {
        const courseID = localStorage.getItem('courseID');
        const courseName = localStorage.getItem('courseName');
        if (courseID && courseName) {
            dispatchCourse({
                type: COURSE_SELECT,
                course: {
                    id: courseID,
                    name: courseName
                }
            });
        }
    }, [dispatchCourse]);

    // eslint-disable-next-line consistent-return
    const login = async (email: string, password: string) => {
        const url = `${BASEURL}/user/${SERVICE_NAME}=login`;
        const formData = new FormData();
        formData.append('username', email);
        formData.append('password', password);
        formData.append('service', MOODLE_SERVICE);
        formData.append('panel', PANEL);
        const response = await axiosServices.post(url, formData);
        if (response.data.success === true) {
            const { token } = response.data.response;
            setSession(token);
            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: true,
                    user: {
                        id: response.data.response.userid,
                        username: response.data.response.username,
                        name: `${response.data.response.firstname} ${response.data.response.lastname}`,
                        firstname: response.data.response.firstname,
                        lastname: response.data.response.lastname,
                        age: response.data.response.age,
                        gender: response.data.response.gender,
                        email: response.data.response.email,
                        location: response.data.response.address,
                        phone: response.data.response.phone,
                        badges: response.data.response.badges,
                        nextBadge: response.data.response.nextbadge,
                        profile_pic: response.data.response.profile_pic,
                        division: response.data.response.division,
                        district: response.data.response.district,
                        upazila: response.data.response.upazila,
                        union: response.data.response.union
                    }
                }
            });
            navigate('/', { replace: true });
        } else {
            dispatch({ type: LOGOUT });
        }
        return response.data;
    };

    const register = async (studentData: StudentAttributeType) => {
        const response = await axiosServices.post('users/students/create/', { data: { type: 'User', attributes: studentData } });
        const userData = response.data.data;
        let users: any[] = [
            {
                id: userData?.id,
                email: userData?.attributes?.profile?.email,
                name: userData?.attributes?.profile?.name
            }
        ];

        if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
            const localUsers = window.localStorage.getItem('users');
            users = [
                ...JSON.parse(localUsers!),
                {
                    id: userData?.id,
                    email: userData?.attributes?.profile?.email,
                    name: userData?.attributes?.profile?.name
                }
            ];
        }

        window.localStorage.setItem('users', JSON.stringify(users));
    };

    const logout = () => {
        setSession(null);
        dispatch({ type: LOGOUT });
    };

    const resetPassword = async (username: string) => {
        const formData = new FormData();
        formData.append('cred', username);
        const url = `${BASEURL}/user/${SERVICE_NAME}=reset_password`;
        const response = await axiosServices.post(url, formData);
        window.localStorage.setItem('reset_token', response.data.response.token);
        window.localStorage.setItem('username', response.data.response.username);
        return response.data;
    };

    const updateProfile = (data: any) => {
        dispatch({
            type: USER_UPDATE,
            payload: {
                isLoggedIn: true,
                user: {
                    id: data.userid,
                    username: data.username,
                    firstname: data.firstname,
                    lastname: data.lastname,
                    age: data.age,
                    gender: data.gender,
                    email: data.email,
                    location: data.address,
                    phone: data.phone,
                    badges: data.badges,
                    nextBadge: data.nextbadge,
                    profile_pic: data.profile_pic,
                    division: data.division,
                    district: data.district,
                    upazila: data.upazila,
                    union: data.union
                }
            }
        });
    };

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider value={{ ...state, login, register, logout, resetPassword, updateProfile }}>{children}</JWTContext.Provider>
    );
};

export default JWTContext;

import { useEffect, useState } from 'react';
import {
    Grid,
    Button,
    FormControl,
    FormHelperText,
    TextField,
    Typography,
    RadioGroup,
    FormControlLabel,
    Radio,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import Wrapper from 'components/Card/Wrapper';
import langString from 'utils/langString';
import axiosServices from 'utils/axiosServices';
import { SnackBarType } from 'types/common';
import { makeFormData, requestUrl } from 'utils/Helpers';
import { useSelector } from 'react-redux';
import { DefaultRootStateProps } from 'types';
import FormValidators from 'utils/FormValidators';

export default function AddStudent() {
    const course = useSelector((state: DefaultRootStateProps) => state.courseData);
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState<SnackBarType>({ open: false, message: '' });
    const [batches, setBatches] = useState<any[]>([]);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [gender, setGender] = useState('Male');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [batch, setBatch] = useState(0);
    const [errors, setErrors] = useState<any>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const saveData = async () => {
        if (course && course.id) {
            setIsSubmitting(true);
            const allErrors = FormValidators(
                ['firstName', 'lastName', 'phone', 'username', 'password'],
                [firstName, lastName, phone, username, password]
            );
            if (allErrors.valid) {
                setLoading(true);
                try {
                    const response = await axiosServices.post(
                        requestUrl('ace_exam', 'add_user_by_teacher'),
                        makeFormData([
                            { key: 'firstname', value: firstName },
                            { key: 'lastname', value: lastName },
                            { key: 'email', value: email },
                            { key: 'phone1', value: phone },
                            { key: 'gender', value: gender },
                            { key: 'username', value: username },
                            { key: 'password', value: password },
                            { key: 'course_id', value: course?.id },
                            { key: 'batch_id', value: batch }
                        ])
                    );
                    if (response.data.success) {
                        setFirstName('');
                        setLastName('');
                        setEmail('');
                        setPhone('');
                        setGender('Male');
                        setUsername('');
                        setPassword('');
                        setBatch(0);
                        setSnackbar({ open: true, message: response.data?.msg, alert: 'success' });
                    } else {
                        setSnackbar({ open: true, message: response.data?.msg, alert: 'error' });
                    }
                    setLoading(false);
                } catch (e) {
                    setLoading(false);
                    console.log(e);
                    setSnackbar({ open: true, message: langString('servererror') });
                }
            } else {
                setErrors(allErrors);
            }
            setIsSubmitting(false);
        } else {
            setSnackbar({ open: true, message: langString('coursenotselected') });
        }
    };

    useEffect(() => {
        const init = async () => {
            setLoading(true);
            try {
                const response = await axiosServices.post(
                    requestUrl('ace_exam', 'get_batches'),
                    makeFormData([{ key: 'course_id', value: course?.id }])
                );
                if (response.data.success) {
                    if (response.data?.response) {
                        setBatches(response.data.response);
                    }
                    setLoading(false);
                }
            } catch (e) {
                console.log(e);
                setLoading(false);
                setSnackbar({ open: true, message: langString('servererror') });
            }
        };
        if (course) {
            init();
        } else {
            setSnackbar({ open: true, message: 'Course not selected' });
        }
    }, [course]);

    return (
        <Wrapper
            title={`${langString('student')} ${langString('enrolment')}`}
            icon={<GroupIcon />}
            snakbar={snackbar}
            snackbarClose={() => setSnackbar({ open: false, message: '' })}
            loading={loading}
            backLink="/enrolment"
        >
            <Grid container spacing={2}>
                <Grid item sm={6}>
                    <FormControl sx={{ marginBottom: 3 }} fullWidth error={Boolean(errors && errors.firstName?.required)}>
                        <TextField
                            id="outlined-firstName"
                            value={firstName}
                            autoFocus
                            name="firstName"
                            onChange={(event: any) => setFirstName(event.target.value)}
                            label={`${langString('firstname')} *`}
                            placeholder={langString('firstName')}
                            inputProps={{}}
                        />
                        {errors && errors.firstName?.required && (
                            <FormHelperText error id="helper-text-firstName">
                                {errors.firstName?.message}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item sm={6}>
                    <FormControl sx={{ marginBottom: 3 }} fullWidth error={Boolean(errors && errors.lastName?.required)}>
                        <TextField
                            id="outlined-lastName"
                            value={lastName}
                            autoFocus
                            name="lastName"
                            onChange={(event: any) => setLastName(event.target.value)}
                            label={`${langString('lastname')} *`}
                            placeholder={langString('lastName')}
                            inputProps={{}}
                        />
                        {errors && errors.lastName?.required && (
                            <FormHelperText error id="helper-text-lastName">
                                {errors.lastName?.message}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item sm={6}>
                    <FormControl sx={{ marginBottom: 3 }} fullWidth error={Boolean(errors && errors.phone?.required)}>
                        <TextField
                            id="outlined-phone"
                            value={phone}
                            autoFocus
                            name="phone"
                            onChange={(event: any) => setPhone(event.target.value)}
                            label={`${langString('phone')} *`}
                            placeholder={langString('phone')}
                            inputProps={{}}
                        />
                        {errors && errors.phone?.required && (
                            <FormHelperText error id="helper-text-phone">
                                {errors.phone?.message}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item sm={6}>
                    <FormControl fullWidth>
                        <Typography>{langString('gender')}</Typography>
                        <RadioGroup
                            row
                            id="gender"
                            aria-label="gender"
                            value={gender}
                            defaultValue={gender}
                            onChange={(event: any) => setGender(event.target.value)}
                            name="gender"
                        >
                            <FormControlLabel value="Male" control={<Radio />} label="Male" />
                            <FormControlLabel value="Female" control={<Radio />} label="Female" />
                            <FormControlLabel value="Other" control={<Radio />} label="Other" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item sm={6}>
                    <FormControl sx={{ marginBottom: 3 }} fullWidth error={Boolean(errors && errors.username?.required)}>
                        <TextField
                            id="outlined-username"
                            value={username}
                            autoFocus
                            name="username"
                            onChange={(event: any) => setUsername(event.target.value)}
                            label={`${langString('username')} *`}
                            placeholder={langString('username')}
                            inputProps={{}}
                        />
                        {errors && errors.username?.required && (
                            <FormHelperText error id="helper-text-username">
                                {errors.username?.message}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item sm={6}>
                    <FormControl sx={{ marginBottom: 3 }} fullWidth error={Boolean(errors && errors.password?.required)}>
                        <TextField
                            id="outlined-password"
                            value={password}
                            type="password"
                            autoFocus
                            name="password"
                            onChange={(event: any) => setPassword(event.target.value)}
                            label={`${langString('password')} *`}
                            placeholder={langString('password')}
                            inputProps={{}}
                        />
                        {errors && errors.password?.required && (
                            <FormHelperText error id="helper-text-password">
                                {errors.password?.message}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item sm={6}>
                    <FormControl sx={{ marginBottom: 3 }} fullWidth error={Boolean(errors && errors.email?.required)}>
                        <TextField
                            id="outlined-email"
                            value={email}
                            autoFocus
                            name="email"
                            onChange={(event: any) => setEmail(event.target.value)}
                            label={langString('email')}
                            placeholder={langString('email')}
                            inputProps={{}}
                        />
                        {errors && errors.email?.required && (
                            <FormHelperText error id="helper-text-email">
                                {errors.email?.message}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item sm={6}>
                    <FormControl sx={{ marginBottom: 3 }} fullWidth error={Boolean(errors && errors.batch?.required)}>
                        <InputLabel id="batch-select-label">{langString('batch')}</InputLabel>
                        <Select
                            name="batch"
                            labelId="batch-select-label"
                            id="batch-select"
                            value={batch}
                            label={langString('batch')}
                            onChange={(event: any) => setBatch(event.target.value)}
                        >
                            <MenuItem value="0" sx={{ color: '#95a5a6' }}>
                                {langString('selectbatch')}
                            </MenuItem>
                            {batches &&
                                batches.map((item: any) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item sm={6}>
                    <FormControl sx={{ margin: 1 }}>
                        <Button
                            variant="contained"
                            size="large"
                            onClick={() => saveData()}
                            color="primary"
                            type="submit"
                            disabled={isSubmitting}
                        >
                            {langString('save')}
                        </Button>
                    </FormControl>
                </Grid>
            </Grid>
        </Wrapper>
    );
}

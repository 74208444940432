import { Box, Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useState, useEffect } from 'react';
import axiosServices from 'utils/axiosServices';
import { getMedianDifficulty, makeFormData, requestUrl } from 'utils/Helpers';
import langString from 'utils/langString';
import Autocomplete from '@mui/material/Autocomplete';
import QuizPreview from '../QuestionBank/Preview';
import { useSelector } from 'react-redux';
import { DefaultRootStateProps } from 'types';

type QuizCreateFormPropsType = {
    name: string;
    setName: (value: string) => void;
    topic: string[];
    setTopic: (values: string[]) => void;
    difficulty: number;
    setDifficulty: (value: number) => void;
    save: () => void;
    submitting: boolean;
    selectedQuestions: any[];
    errors: any;
};
export default function QuizCreateForm({
    name,
    setName,
    topic,
    setTopic,
    difficulty,
    setDifficulty,
    save,
    selectedQuestions,
    errors,
    submitting
}: QuizCreateFormPropsType) {
    const [topics, setTopics] = useState<any[]>([]);
    const [difficulties, setDifficulties] = useState<any[]>([]);
    const [selectedTopics, setSelectedTopics] = useState<any[]>([]);
    const course = useSelector((state: DefaultRootStateProps) => state.courseData);
    // Taking the most common question difficulty
    if (selectedQuestions.length > 0) {
        const mostCommonDif = getMedianDifficulty(selectedQuestions);
        setDifficulty(mostCommonDif ? mostCommonDif.id : 0);
    }
    useEffect(() => {
        if (topic) {
            const topicList = topic.map((item: any) => ({ id: item.id, name: item.name }));
            setSelectedTopics(topicList);
        }
    }, [topic]);

    useEffect(() => {
        const init = async () => {
            try {
                const response = await axiosServices.post(
                    requestUrl('ace_exam', 'get_topics'),
                    makeFormData([
                        { key: 'course_id', value: course?.id || 0 },
                        { key: 'category_id', value: course?.categoryID || 0 }
                    ])
                );
                if (response.status === 200 && response.data.success) {
                    if (response.data?.response) {
                        setTopics(response.data.response);
                    }
                }
            } catch (e) {
                console.log(e);
            }
        };
        init();
    }, [course]);

    useEffect(() => {
        const init = async () => {
            try {
                const response = await axiosServices.post(requestUrl('ace_exam', 'get_dificulties'));
                if (response.status === 200 && response.data.success) {
                    if (response.data?.response) {
                        setDifficulties(response.data.response);
                    }
                }
            } catch (e) {
                console.log(e);
            }
        };
        init();
    }, []);

    return (
        <Box>
            <Grid container>
                <Grid item sm={6}>
                    <FormControl>
                        <TextField
                            size="small"
                            id="outlined-name"
                            value={name}
                            autoFocus
                            name="name"
                            onChange={(event: any) => setName(event.target.value)}
                            label={`*${langString('name')}`}
                            placeholder={langString('name')}
                        />
                        {errors && errors.name?.required && (
                            <FormHelperText error id="helper-text-name">
                                {errors.name?.message}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item sm={6}>
                    <FormControl fullWidth>
                        <InputLabel id="difficulty-select-label">{`*${langString('difficulty')}`}</InputLabel>
                        <Select
                            size="small"
                            name="difficultys"
                            labelId="difficulty-select-label"
                            id="difficulty-select"
                            value={difficulty}
                            label={`*${langString('difficulty')}`}
                            onChange={(event: any) => setDifficulty(event.target.value)}
                        >
                            <MenuItem value={0}>
                                {langString('select')} {langString('difficulty')}
                            </MenuItem>
                            {difficulties &&
                                difficulties.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.name?.toUpperCase()}
                                    </MenuItem>
                                ))}
                        </Select>
                        {errors && errors.difficulty?.required && (
                            <FormHelperText error id="helper-text-difficulty">
                                {errors.difficulty?.message}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ marginTop: 1 }}>
                <Grid item sm={7}>
                    <Autocomplete
                        size="small"
                        multiple
                        id="multiple-limit-tags"
                        options={topics}
                        getOptionLabel={(option) => option.name}
                        value={selectedTopics}
                        renderInput={(params) => <TextField {...params} label={langString('topic')} placeholder={langString('topic')} />}
                        onChange={(event: any, value: any) => value && setTopic(value)}
                    />
                </Grid>
                <Grid item>
                    <QuizPreview questions={selectedQuestions} marks={1} />
                </Grid>
                <Grid item>
                    <Button color="primary" variant="contained" onClick={save} disabled={submitting}>
                        {langString('save')}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}

import {
    Checkbox,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import { checkNullInfo } from 'utils/Helpers';

// Props type
export type SelectColumnType = {
    header: string;
    accessor: string;
    content?: any;
};

type SelectTableProps = {
    columns: SelectColumnType[];
    rows: any[];
    count: number;
    page: number;
    rowsPerPage: number;
    setPage: (value: number) => void;
    setRowsPerPage: (value: number) => void;
    idField: string;
    selectedFields: number[];
    checkAll: boolean;
    changeCheckAll: (value: boolean) => void;
    checkUpdate: (id: number) => void;
    link?: string;
    linkID?: string;
    showSL?: boolean;
};

// ==============================|| TABLE - BASIC ||============================== //

export default function SelectTable({
    columns,
    rows,
    count,
    page,
    rowsPerPage,
    link,
    linkID,
    setPage,
    setRowsPerPage,
    idField,
    selectedFields,
    checkAll,
    changeCheckAll,
    checkUpdate,
    showSL
}: SelectTableProps) {
    const navigate = useNavigate();
    const classes = useStyle();

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const renderCell = (item: any, column: any) => {
        if (column.content) {
            return column.content(item);
        }
        return <Typography noWrap>{checkNullInfo(item[column.accessor])}</Typography>;
    };

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <MainCard content={false}>
                    <TableContainer>
                        <Table sx={{ minWidth: 350 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell component="th">
                                        <Checkbox checked={checkAll} onChange={() => changeCheckAll(!checkAll)} />
                                    </TableCell>
                                    {showSL && (
                                        <TableCell align="center">
                                            <Typography variant="subtitle1" noWrap>
                                                SL
                                            </Typography>
                                        </TableCell>
                                    )}
                                    {columns.map((column: any, index: number) => (
                                        <TableCell key={index} align="center">
                                            <Typography variant="subtitle1" noWrap>
                                                {column.header}
                                            </Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.length ? (
                                    rows.map((row: any, i: number) => (
                                        <TableRow
                                            key={i}
                                            className={classes.rowsHover}
                                            onClick={() => (linkID ? navigate(`${link + row[linkID]}/`) : '')}
                                        >
                                            <TableCell>
                                                <Checkbox
                                                    checked={selectedFields.includes(row[idField])}
                                                    onChange={() => row[idField] && checkUpdate(row[idField])}
                                                />
                                            </TableCell>
                                            {showSL && (
                                                <TableCell align="center">
                                                    <Typography variant="subtitle1" noWrap>
                                                        {i + 1}
                                                    </Typography>
                                                </TableCell>
                                            )}
                                            {columns.map((column: SelectColumnType, k: number) => (
                                                <TableCell align="center" key={k}>
                                                    {renderCell(row, column)}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow className={classes.rowsHover}>
                                        <TableCell align="center" colSpan={columns.length}>
                                            <h3>No Data Found</h3>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {count
                        ? page !== undefined &&
                          rowsPerPage !== undefined && (
                              <TablePagination
                                  component="div"
                                  count={count}
                                  page={page}
                                  onPageChange={handleChangePage}
                                  rowsPerPage={rowsPerPage}
                                  onRowsPerPageChange={handleChangeRowsPerPage}
                              />
                          )
                        : ''}
                </MainCard>
            </Grid>
        </Grid>
    );
}

const useStyle = makeStyles({
    rowsHover: {
        borderBottom: '1px solid #DDD',
        '&:hover': {
            backgroundColor: '#FFE'
        }
    }
});

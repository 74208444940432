import { CourseType } from 'types/course';
import { COURSE_REOMVE, COURSE_SELECT } from './actions';

export interface CourseReducerActionProps {
    type: string;
    course: CourseType | null;
}

const courseReducer = (state: CourseType | null, action: CourseReducerActionProps) => {
    const course = action.course || null;
    switch (action.type) {
        case COURSE_SELECT:
            return {
                ...state,
                id: course?.id,
                name: course?.name,
                categoryID: course?.categoryID,
                categoryName: course?.categoryName,
                topics: course?.topics,
                difficulties: course?.difficulties
            };
        case COURSE_REOMVE: {
            return {
                ...state,
                course
            };
        }
        default:
            return state || null;
    }
};

export default courseReducer;

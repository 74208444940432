import { useState, useEffect } from 'react';
import { Box, FormControl, Grid, Typography, InputLabel, Select, MenuItem, Button } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import axiosServices from 'utils/axiosServices';
import { makeFormData, requestUrl, barChartData, sortedPairedDataList, marksColor } from 'utils/Helpers';
import langString from 'utils/langString';
import { BarOptions, BarPairedSeries, LineOptions } from 'utils/ChartTypes';

type DashboardChartAllStudentsPropsType = {
    batches: any[];
};

const chartToolbar = {
    show: true,
    offsetX: 0,
    offsetY: 0,
    tools: {
        download: true,
        selection: true,
        zoom: true,
        zoomin: true,
        zoomout: true,
        pan: true,
        reset: true,
        customIcons: []
    },
    export: {
        csv: {
            filename: 'AverageMarkComp',
            columnDelimiter: ',',
            headerCategory: 'Student Name',
            headerValue: 'value',
            dateFormatter(timestamp: any) {
                return new Date(timestamp).toDateString();
            }
        },
        svg: {
            filename: 'AverageMarkComp'
        },
        png: {
            filename: 'AverageMarkComp'
        }
    },
    autoSelected: 'zoom'
};

export default function DashboardChartAllStudents({ batches }: DashboardChartAllStudentsPropsType) {
    const [options, setOptions] = useState<BarOptions | LineOptions | null>(null);
    const [lineOptions, setLineOptions] = useState<LineOptions | null>(null);
    const [series, setSeries] = useState<BarPairedSeries | null>(null);
    const [batchID, setBatchID] = useState(0);
    const [labels, setLabels] = useState([]);
    const [sortBy, setSortBy] = useState('def');
    const [chartType, setChartType] = useState('bar');

    const handleChartChange = (type: string) => {
        setChartType(type);
        setLineOptions({
            chart: {
                height: 'auto',
                type: 'line',
                zoom: {
                    enabled: true
                },
                toolbar: chartToolbar
            },
            // colors: ['#2E93fA'],
            dataLabels: {
                enabled: false
            },
            title: {
                text: 'Average Mark',
                align: 'left'
            },
            stroke: {
                curve: 'straight'
            },
            xaxis: {
                categories: labels
            },
            markers: {
                size: 4,
                colors: '#2E93fA',
                strokeColors: '#fff'
            }
        });
    };

    useEffect(() => {
        if (batches && batches.length) {
            setBatchID(batches[0].id);
        }
    }, [batches]);

    useEffect(() => {
        const init = async () => {
            try {
                const response = await axiosServices.post(
                    requestUrl('dashboard', 'batch_students_mark'),
                    makeFormData([{ key: 'batch_id', value: batchID }])
                );
                if (response.data.success) {
                    if (response.data?.response && response.data?.response.length) {
                        const dataList: [] = barChartData(response.data.response, 'name', 'attemptedQuizMarkPercentage');
                        const labelsArray = response.data.response.map((item: any) => item.name);
                        setLabels(labelsArray);
                        const sortedPairedData: object[] = sortedPairedDataList(dataList, sortBy);
                        setSeries([{ name: '%', data: sortedPairedData }]);
                        const barColors: string[] = sortedPairedData.map((data: any) => marksColor(parseInt(data.y, 10)));
                        setOptions({
                            chart: {
                                type: 'bar',
                                height: 350,
                                toolbar: chartToolbar
                            },
                            colors: barColors,
                            plotOptions: {
                                bar: {
                                    distributed: true,
                                    borderRadius: 4,
                                    horizontal: true
                                }
                            },
                            title: {
                                text: 'Average Mark',
                                align: 'left'
                            },
                            dataLabels: {
                                enabled: false
                            },
                            xaxis: {
                                type: 'category'
                                // categories: labelsArray
                            }
                        });
                    } else {
                        setOptions(null);
                    }
                }
            } catch (e) {
                console.log(e);
            }
        };
        if (batchID) {
            init();
        }
    }, [batchID, sortBy]);

    return (
        <Box sx={{ marginY: 3, borderBottom: '1px solid #DDD', padding: 2 }}>
            <Grid container spacing={2}>
                <Grid item sm={6}>
                    <Box sx={{ padding: 1.5, border: '1px solid #e67e22', borderRadius: '10px', textAlign: 'center' }}>
                        <Typography variant="h5" sx={{ color: '#e67e22' }}>
                            Averege Marks Comparison of All Students
                        </Typography>
                    </Box>
                </Grid>
                <Grid item sm={3}>
                    <FormControl sx={{ paddingRight: 1, float: 'right', minWidth: '150px' }}>
                        <InputLabel id="batch-select-label">{langString('batch')}</InputLabel>
                        <Select
                            size="small"
                            name="batchs"
                            labelId="batch-select-label"
                            id="batch-select"
                            value={batchID}
                            label={langString('batch')}
                            onChange={(event: any) => setBatchID(event.target.value)}
                        >
                            {batches.map((item: any) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sm={3}>
                    <FormControl sx={{ paddingRight: 1, minWidth: '150px' }}>
                        <InputLabel id="sort-select-label">{langString('sort')}</InputLabel>
                        <Select
                            size="small"
                            name="sorts"
                            labelId="sort-select-label"
                            id="sort-select"
                            value={sortBy}
                            label={langString('sort')}
                            onChange={(event: any) => {
                                setSortBy(event.target.value);
                            }}
                        >
                            <MenuItem value="def">Default</MenuItem>
                            <MenuItem value="asc">Ascending</MenuItem>
                            <MenuItem value="desc">Descending</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ marginY: 1 }}>
                <Grid item xs={12} marginTop={2}>
                    <Grid container spacing={8} justifyContent="center" alignItems="center">
                        <Grid item>
                            <Button
                                variant="contained"
                                sx={{ backgroundColor: chartType === 'bar' ? '#00807e' : '#cfcfcf' }}
                                onClick={() => handleChartChange('bar')}
                            >
                                Bar Chart
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                sx={{ backgroundColor: chartType === 'line' ? '#00807e' : '#cfcfcf' }}
                                onClick={() => handleChartChange('line')}
                            >
                                Trend Line
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={12} sx={{ display: chartType !== 'bar' ? 'none' : '' }}>
                    {options && series ? (
                        <ReactApexChart options={options} series={series} type="bar" height="auto" />
                    ) : (
                        <Typography variant="h3" sx={{ textAlign: 'center' }}>
                            {langString('nodatafound')}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={12} sx={{ display: chartType !== 'line' ? 'none' : '' }}>
                    {lineOptions && series ? (
                        <ReactApexChart options={lineOptions} series={series} type="line" height="auto" />
                    ) : (
                        <Typography variant="h3" sx={{ textAlign: 'center' }}>
                            {langString('nodatafound')}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
}

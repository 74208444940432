import { useState, useEffect } from 'react';
import { IconButton, Modal, Tooltip } from '@mui/material';

import SendIcon from '@mui/icons-material/Send';
import langString from 'utils/langString';
import { requestUrl, makeFormData } from 'utils/Helpers';
import axiosServices from 'utils/axiosServices';
import { useSelector } from 'react-redux';
import { DefaultRootStateProps } from 'types';
import PublishQuizModal from './PublishQuizModal';

export type QuizType = 'scheduled' | 'self' | 'deadline' | string;
export type QuizNotificationType = 'emailStudent' | 'emailParents' | 'smsStudent' | 'smsParents' | 'access_code';
export type QuizDurationType = {
    hour: number;
    minute: number;
};
export type QuizDeployParamType = {
    id: number;
    batches: number[];
    quizType: QuizType;
    viewType: number | 1 | 2;
    questionSet: number;
    notifications: QuizNotificationType[];
    code: string;
    quizAttemptType: number | 1 | 2;
    timeOpen: string;
    timeClose: string;
    deadline: string;
    timeLimit: number;
};
type QuizPublishPropsType = {
    quizID: number;
    deploy: (quizID: QuizDeployParamType) => void;
    refreshQuizes: () => void;
    status: boolean;
};
export default function QuizPublish({ quizID, deploy, refreshQuizes, status }: QuizPublishPropsType) {
    const unixDate = Math.floor(new Date().getTime() / 1000);
    const [open, setOpen] = useState(false);
    const [quiz, setQuiz] = useState<any>(null);
    const [batches, setBatches] = useState<any[]>([]);
    const [selectedBatches, setSelectedBatches] = useState<number[]>([]);
    const [notifications, setNotifications] = useState<QuizNotificationType[]>([]);
    const [qtype, setQtype] = useState<QuizType>('scheduled'); // self, scheduled, deadline
    const [viewType, setViewType] = useState<number>(1); // 1 for all together, 2 for one by one
    const [sets, setSets] = useState(1);
    const [code, setCode] = useState('');
    const [quizAttemptType, setQuizAttemptType] = useState<number>(1);
    const [quizTime, setQuizTime] = useState(String(unixDate));
    const [quizTimeClose, setQuizTimeClose] = useState(String(unixDate));
    const [quizDeadline, setQuizDeadline] = useState(String(unixDate));
    const [quizDuration, setQuizDuration] = useState<QuizDurationType>({ hour: 0, minute: 10 });
    const course = useSelector((state: DefaultRootStateProps) => state.courseData);

    useEffect(() => {
        const init = async () => {
            try {
                const response = await axiosServices.post(
                    requestUrl('ace_exam', 'get_quiz_details'),
                    makeFormData([{ key: 'quiz_id', value: quizID }])
                );
                if (response.status === 200 && response.data.success) {
                    if (response.data?.response) {
                        setQuiz(response.data.response);
                    }
                }
            } catch (e) {
                console.log(e);
            }
        };
        if (quizID) {
            init();
        }
    }, [quizID]);

    useEffect(() => {
        const init = async () => {
            try {
                const response = await axiosServices.post(
                    requestUrl('ace_exam', 'get_batches'),
                    makeFormData([{ key: 'course_id', value: course?.id }])
                );
                if (response.data.success) {
                    if (response.data?.response) {
                        setBatches(response.data.response);
                    }
                }
            } catch (e) {
                console.log(e);
            }
        };
        if (course) {
            init();
        }
    }, [course]);

    return (
        <>
            <Tooltip title={langString('publish')}>
                <IconButton onClick={() => setOpen(true)}>
                    <SendIcon />
                </IconButton>
            </Tooltip>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <PublishQuizModal
                    open={open}
                    setOpen={(value) => setOpen(value)}
                    quiz={quiz}
                    setQuiz={(value) => setQuiz(value)}
                    batches={batches}
                    setBatches={(value: any) => setBatches(value)}
                    selectedBatches={selectedBatches}
                    setSelectedBatches={(value: any) => setSelectedBatches(value)}
                    notifications={notifications}
                    setNotifications={(value: any) => setNotifications(value)}
                    qtype={qtype}
                    setQtype={(value: any) => setQtype(value)}
                    viewType={viewType}
                    setViewType={(value: number) => setViewType(value)}
                    sets={sets}
                    setSets={(value: any) => setSets(value)}
                    code={code}
                    setCode={(value: any) => setCode(value)}
                    quizAttemptType={quizAttemptType}
                    setQuizAttemptType={(value: number) => setQuizAttemptType(value)}
                    quizTime={quizTime}
                    setQuizTime={(value: any) => setQuizTime(value)}
                    quizTimeClose={quizTimeClose}
                    setQuizTimeClose={(value: any) => setQuizTimeClose(value)}
                    quizDeadline={quizDeadline}
                    setQuizDeadline={(value: any) => setQuizDeadline(value)}
                    quizDuration={quizDuration}
                    setQuizDuration={(value: any) => setQuizDuration(value)}
                    quizID={quizID}
                    deploy={(value: any) => deploy(value)}
                    refreshQuizes={refreshQuizes}
                />
            </Modal>
        </>
    );
}

import { useState, useEffect } from 'react';
import { Box, Grid, TablePagination, Typography, Fab, Checkbox, FormControlLabel } from '@mui/material';
import QuizIcon from '@mui/icons-material/Quiz';
import Wrapper from 'components/Card/Wrapper';
import langString from 'utils/langString';
import QuestionBankFilter from './Filter';
import Question from './Question';
import axiosServices from 'utils/axiosServices';
import { makeFormData, requestUrl } from 'utils/Helpers';
import ManageQuiz from '../Manage';
import { useParams } from 'react-router-dom';
import { SnackBarType } from 'types/common';
import { useSelector, useDispatch } from 'react-redux';
import { DefaultRootStateProps } from 'types';
import { ADD_ALL_QUESTIONS, ADD_SELECTED_QUESTION, REMOVE_ALL_SELECTED_QUESTIONS, REMOVE_QUESTION } from 'store/actions';

export default function QuestionBank() {
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState<SnackBarType>({ open: false, message: '' });
    const [topics, setTopics] = useState<string[]>([]);
    const [year, setYear] = useState(0);
    const [session, setSession] = useState('');
    const [variant, setVariant] = useState('');
    const [subCategory, setSubCategory] = useState(0);
    const [difficulty, setDifficulty] = useState(0);
    const [search, setSearch] = useState('');
    const [nameSearch, setNameSearch] = useState('');
    const [questions, setQuestions] = useState<any[]>([]);
    const [selectedQuestions, setSelectedQuestions] = useState<any[]>([]);
    const [selectedIDs, setSelectedIDs] = useState<number[]>([]);
    const [selectAll, setSelectAll] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const { quizID } = useParams();
    const course = useSelector((state: DefaultRootStateProps) => state.courseData);
    const dispatchQuestionInfo = useDispatch();

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const selectAllQuestions = () => {
        if (selectAll) {
            setSelectAll(false);
            setSelectedQuestions([]);
            setSelectedIDs([]);
            dispatchQuestionInfo({
                type: REMOVE_ALL_SELECTED_QUESTIONS
            });
        } else {
            setSelectAll(true);
            const allQuestions = [...questions];
            dispatchQuestionInfo({
                type: ADD_ALL_QUESTIONS,
                allQuestions
            });
            const allSelectedQuestions: any[] = [];
            const allSelectedID: number[] = [];
            allQuestions.forEach((item) => {
                allSelectedQuestions.push(item);
                allSelectedID.push(item.id);
            });
            setSelectedIDs(allSelectedID);
            setSelectedQuestions(allSelectedQuestions);
        }
    };
    const addQuestion = (question: any) => {
        const allSelectedQuestions = [...selectedQuestions];
        const exists = allSelectedQuestions.find((item: any) => item.id === question.id);
        if (!exists) {
            allSelectedQuestions.push(question);
            setSelectedQuestions(allSelectedQuestions);
            const allSelected = [...selectedIDs];
            allSelected.push(question.id);
            setSelectedIDs(allSelected);
        }
        dispatchQuestionInfo({
            type: ADD_SELECTED_QUESTION,
            question
        });
    };

    const removeQuestion = (question: any) => {
        setSelectAll(false);
        const allSelectedQuestions = [...selectedQuestions];
        const filteredQuestions = allSelectedQuestions.filter((item: any) => question.id !== item.id);
        setSelectedQuestions(filteredQuestions);
        const allSelected = [...selectedIDs];
        const filtered = allSelected.filter((item) => item !== question.id);
        setSelectedIDs(filtered);
        dispatchQuestionInfo({
            type: REMOVE_QUESTION,
            question
        });
    };

    const selectionAction = (status: boolean, question: any) => (status ? addQuestion(question) : removeQuestion(question));

    useEffect(() => {
        const init = async () => {
            setLoading(true);
            setSelectAll(false);
            try {
                const response = await axiosServices.post(
                    requestUrl('ace_exam', 'question_bank'),
                    makeFormData([
                        { key: 'course_id', value: course?.id },
                        { key: 'sub_category_type', value: subCategory },
                        { key: 'difficulty', value: difficulty },
                        { key: 'year', value: year },
                        { key: 'session', value: session },
                        { key: 'variant', value: variant },
                        { key: 'topics', value: topics },
                        { key: 'search', value: search },
                        { key: 'name_search', value: nameSearch },
                        { key: 'rows_per_page', value: rowsPerPage },
                        { key: 'page_no', value: page }
                    ])
                );
                if (response.data.success) {
                    if (response.data?.response) {
                        setQuestions(response.data.response?.data);
                        setCount(response.data.response?.totalCount);
                        setLoading(false);
                    }
                }
            } catch (e) {
                console.log(e);
                setLoading(false);
                setSnackbar({ open: true, message: langString('servererror') });
            }
        };
        if (course) {
            init();
        }
    }, [course, subCategory, year, difficulty, topics, search, nameSearch, rowsPerPage, page, session, variant]);

    useEffect(() => {
        const init = async () => {
            try {
                const response = await axiosServices.post(
                    requestUrl('ace_exam', 'get_quiz_details'),
                    makeFormData([{ key: 'quiz_id', value: quizID || 0 }])
                );
                if (response.data.success) {
                    if (response.data?.response) {
                        setSelectedQuestions(response.data.response?.question);
                        const selectedQuesIDs = response.data.response?.question.map((item: any) => item.id);
                        setSelectedIDs(selectedQuesIDs);
                    }
                }
            } catch (e) {
                console.log(e);
            }
        };
        if (quizID) {
            init();
            setShowForm(true);
        } else {
            setSelectedIDs([]);
            setSelectedQuestions([]);
        }
    }, [quizID]);

    useEffect(() => {
        if (course) {
            setSelectedQuestions([]);
            setSelectedIDs([]);
        }
    }, [course]);

    return (
        <Wrapper
            title={langString('questionbank')}
            icon={<QuizIcon />}
            loading={loading}
            snakbar={snackbar}
            snackbarClose={() => setSnackbar({ open: false, message: '' })}
        >
            <Grid container spacing={2}>
                <Grid item sm={12} md={showForm ? 6 : 12}>
                    <QuestionBankFilter
                        courseID={course?.id}
                        categoryID={course?.categoryID}
                        categoryName={course?.categoryName}
                        setTopics={(values) => setTopics(values)}
                        subCategory={subCategory}
                        setSubCategory={(value) => setSubCategory(value)}
                        year={year}
                        setYear={(value) => setYear(value)}
                        session={session}
                        setSession={(value) => setSession(value)}
                        variant={variant}
                        setVariant={(value) => setVariant(value)}
                        difficulty={difficulty}
                        setDifficulty={(value) => setDifficulty(value)}
                        search={search}
                        setSearch={(value) => setSearch(value)}
                        nameSearch={nameSearch}
                        setNameSearch={(value) => setNameSearch(value)}
                        setSelectedQuestions={(value) => setSelectedQuestions(value)}
                        setSelectedIDs={(value) => setSelectedIDs(value)}
                        questions={questions}
                    />
                    <Box sx={{ padding: 1, border: '1px solid #EEE', marginTop: 3 }}>
                        <Grid container sx={{ marginTop: 2 }}>
                            <Grid item sm={3}>
                                <FormControlLabel
                                    sx={{ marginLeft: 3 }}
                                    control={<Checkbox checked={selectAll} onChange={() => selectAllQuestions()} />}
                                    label="Select All"
                                />
                            </Grid>
                        </Grid>
                        <Box sx={{ marginTop: 2 }}>
                            {questions.length ? (
                                questions.map((que, key: number) => (
                                    <Question
                                        key={que.id}
                                        selected={selectedIDs.includes(que.id)}
                                        serial={key}
                                        name={que.name}
                                        options={que.answers}
                                        description={que.questiontext}
                                        slot="bank"
                                        actionUpdate={(status: boolean) => selectionAction(status, que)}
                                    />
                                ))
                            ) : (
                                <Typography variant="h4" textAlign="center">
                                    {langString('noquestions')}
                                </Typography>
                            )}
                        </Box>
                        {count ? (
                            <TablePagination
                                component="div"
                                count={count}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                rowsPerPageOptions={[10, 25, 50]}
                            />
                        ) : (
                            ''
                        )}
                    </Box>
                </Grid>
                {showForm && (
                    <Grid item sm={12} md={6}>
                        <ManageQuiz
                            action={quizID ? 'edit' : 'create'}
                            quizID={quizID ? parseInt(quizID, 10) : 0}
                            questions={selectedQuestions}
                            setQuestions={(values: any[]) => setSelectedQuestions(values)}
                            removeQuestion={(questionItem: any) => removeQuestion(questionItem)}
                            resetSelections={() => setSelectedIDs([])}
                        />
                    </Grid>
                )}
            </Grid>
            <Fab
                sx={{ margin: 0, top: 'auto', right: 30, bottom: 30, left: 'auto', position: 'fixed' }}
                variant="extended"
                color="secondary"
                aria-label="Selected Questions"
                onClick={() => setShowForm(!showForm)}
            >
                {langString('questions')}: {selectedIDs.length}
            </Fab>
        </Wrapper>
    );
}

import React from 'react';
import { Button, Grid, Typography, Box, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import langString from 'utils/langString';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AddBoxIcon from '@mui/icons-material/AddBox';
import MainCard from 'ui-component/cards/MainCard';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { SnackBarType } from 'types/common';
import Loader from 'ui-component/Loader';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => (
    <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

type WrapperPropsType = {
    title: string;
    icon?: React.ReactNode;
    backLink?: string;
    addLink?: string;
    snakbar?: SnackBarType;
    loading?: boolean;
    snackbarClose?: () => void;
    children: React.ReactNode;
};

export default function Wrapper({ title, icon, backLink, addLink, snakbar, snackbarClose, loading, children }: WrapperPropsType) {
    return (
        <Box sx={{ minHeight: '80vh', background: '#FFF', padding: 1.5, borderRadius: 3 }}>
            {loading && <Loader />}
            <Grid container sx={{ marginBottom: 1 }}>
                <Grid item xs={8} sm={8} lg={8} xl={8}>
                    {icon && <IconButton sx={{ display: 'inline', cursor: 'default' }}>{icon}</IconButton>}
                    <Typography sx={{ color: '#000', fontSize: 18, fontWeight: 600, display: 'inline' }}>{title}</Typography>
                </Grid>
                <Grid item xs={4} sm={4} lg={4} xl={4}>
                    {backLink && (
                        <Button sx={{ float: 'right' }} size="small" component={Link} to={backLink} variant="contained" color="info">
                            <ArrowBackIosIcon />
                            {langString('back')}
                        </Button>
                    )}
                    {addLink && (
                        <Button sx={{ float: 'right' }} size="small" component={Link} to={addLink} variant="contained" color="primary">
                            <AddBoxIcon />
                            {langString('add')}
                        </Button>
                    )}
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <MainCard>{children}</MainCard>
                </Grid>
            </Grid>
            {snakbar?.open && (
                <Snackbar open={snakbar.open} autoHideDuration={5000} onClose={snackbarClose}>
                    <Alert onClose={snackbarClose} severity={snakbar.alert || 'error'} sx={{ width: '100%' }}>
                        {snakbar.message || 'An Error Occured'}
                    </Alert>
                </Snackbar>
            )}
        </Box>
    );
}

import { Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Button, Modal } from '@mui/material';
import { useState, useEffect } from 'react';
import axiosServices from 'utils/axiosServices';
import { makeFormData, requestUrl } from 'utils/Helpers';
import langString from 'utils/langString';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import AutoGenerateQuiz from './AutoGenerate';
import { sessionData, yearData, variantData } from 'utils/FilterOptions';

type QuestionBankFilterPropsType = {
    courseID: number | string;
    categoryID?: number | string;
    categoryName?: string;
    setTopics: (values: string[]) => void;
    subCategory?: number;
    setSubCategory: (value: number) => void;
    year: number;
    setYear: (value: number) => void;
    session: string;
    setSession: (value: string) => void;
    variant: string;
    setVariant: (value: string) => void;
    difficulty: number;
    setDifficulty: (value: number) => void;
    search: string;
    setSearch: (value: string) => void;
    nameSearch: string;
    setNameSearch: (value: string) => void;
    setSelectedQuestions: (value: any) => void;
    setSelectedIDs: (value: any) => void;
    questions: any[];
};
export default function QuestionBankFilter({
    courseID,
    categoryID,
    categoryName,
    setTopics,
    subCategory,
    setSubCategory,
    year,
    setYear,
    session,
    setSession,
    variant,
    setVariant,
    difficulty,
    setDifficulty,
    search,
    setSearch,
    nameSearch,
    setNameSearch,
    setSelectedQuestions,
    setSelectedIDs,
    questions
}: QuestionBankFilterPropsType) {
    // const course = useSelector((state: DefaultRootStateProps) => state.courseData);
    const [topicList, setTopicList] = useState<any[]>([]);
    const [difficulties, setDifficulties] = useState<any[]>([]);
    const [open, setOpen] = useState(false);

    const questionNameOptions = createFilterOptions({
        matchFrom: 'any', // start or any
        stringify: (option: any) => option.name
    });

    const topicOptions = createFilterOptions({
        matchFrom: 'any', // start or any
        stringify: (option: any) => option.name
    });

    const topicChange = (event: any, value: any) => {
        if (value) {
            const topicIDs = value.map((item: any) => item.id);
            setTopics(topicIDs);
        }
    };

    useEffect(() => {
        const init = async () => {
            try {
                const response = await axiosServices.post(
                    requestUrl('ace_exam', 'get_topics'),
                    makeFormData([
                        { key: 'course_id', value: courseID || 0 },
                        { key: 'category_id', value: categoryID || 0 }
                    ])
                );
                if (response.status === 200 && response.data.success) {
                    if (response.data?.response) {
                        setTopicList(response.data.response);
                    }
                }
            } catch (e) {
                console.log(e);
            }
        };
        init();
    }, [categoryID, courseID]);

    useEffect(() => {
        const init = async () => {
            try {
                const response = await axiosServices.post(requestUrl('ace_exam', 'get_dificulties'));
                if (response.status === 200 && response.data.success) {
                    if (response.data?.response) {
                        setDifficulties(response.data.response);
                    }
                }
            } catch (e) {
                console.log(e);
            }
        };
        init();
    }, []);

    return (
        <>
            <Box>
                <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <Autocomplete
                                size="small"
                                multiple
                                id="multiple-limit-tags"
                                options={topicList}
                                getOptionLabel={(option) => option.name}
                                filterOptions={topicOptions}
                                renderInput={(params) => (
                                    <TextField {...params} label={langString('topics')} placeholder={langString('topics')} />
                                )}
                                onChange={(event: any, value: any) => topicChange(event, value)}
                            />
                        </FormControl>
                    </Grid>
                    {categoryName === 'Cambridge O-Level' && (
                        <Grid item xs={4}>
                            <TextField
                                select
                                size="small"
                                id="outlined-basic"
                                label={langString('subcategory')}
                                variant="outlined"
                                fullWidth
                                value={subCategory}
                                onChange={(event: any) => setSubCategory(event.target.value)}
                            >
                                <MenuItem value={0}>
                                    {langString('select')} {langString('subcategory')}
                                </MenuItem>
                                <MenuItem value={1}>{langString('gce')}</MenuItem>
                                <MenuItem value={2}>{langString('igcse')}</MenuItem>
                            </TextField>
                        </Grid>
                    )}
                    <Grid item xs={4}>
                        <TextField
                            select
                            size="small"
                            id="outlined-basic"
                            label={langString('year')}
                            variant="outlined"
                            fullWidth
                            value={year}
                            onChange={(event: any) => setYear(event.target.value)}
                        >
                            <MenuItem value={0}>{langString('all')}</MenuItem>
                            {yearData.map((yearObj: any) => (
                                <MenuItem key={yearObj.label} value={yearObj.value}>
                                    {yearObj.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            select
                            size="small"
                            id="outlined-basic"
                            label={langString('session')}
                            variant="outlined"
                            fullWidth
                            value={session}
                            defaultValue={session}
                            onChange={(event: any) => setSession(event.target.value)}
                        >
                            {sessionData.map((sessionObj: any) => (
                                <MenuItem key={sessionObj.label} value={sessionObj.value}>
                                    {sessionObj.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            select
                            size="small"
                            id="outlined-basic"
                            label={langString('variant')}
                            variant="outlined"
                            fullWidth
                            value={variant}
                            onChange={(event: any) => setVariant(event.target.value)}
                        >
                            <MenuItem value="">{langString('all')}</MenuItem>
                            {variantData.map((variantObj: any) => (
                                <MenuItem key={variantObj.label} value={variantObj.value}>
                                    {variantObj.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <InputLabel id="difficulty-select-label">{langString('difficulty')}</InputLabel>
                            <Select
                                size="small"
                                name="difficultys"
                                labelId="difficulty-select-label"
                                id="difficulty-select"
                                value={difficulty}
                                label={langString('difficulty')}
                                onChange={(event: any) => setDifficulty(event.target.value)}
                            >
                                <MenuItem value={0}>
                                    {langString('select')} {langString('difficulty')}
                                </MenuItem>
                                {difficulties &&
                                    difficulties.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.name?.toUpperCase()}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    {/* <Grid item xs={4}>
                        <FormControl fullWidth>
                            <TextField
                                size="small"
                                id="outlined-search"
                                value={nameSearch}
                                autoFocus
                                name="name_search"
                                onChange={(event: any) => setNameSearch(event.target.value)}
                                label="Question Name Search"
                                placeholder="Question Name Search"
                            />
                        </FormControl>
                    </Grid> */}
                    <Grid item xs={4}>
                        {/* <Autocomplete
                            id="free-solo-demo"
                            freeSolo
                            options={questions.map((option) => option.name)}
                            filterOptions={questionNameOptions}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    size="small"
                                    id="outlined-search"
                                    value={nameSearch}
                                    autoFocus
                                    name="name_search"
                                    onChange={(event: any) => setNameSearch(event.target.value)}
                                    label="Question Name Search"
                                    placeholder="Question Name Search"
                                />
                            )}
                        /> */}
                        <Autocomplete
                            id="filter-demo"
                            options={questions}
                            getOptionLabel={(option) => option.name}
                            filterOptions={questionNameOptions}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    size="small"
                                    id="outlined-search"
                                    value={nameSearch}
                                    autoFocus
                                    name="name_search"
                                    onChange={(event: any) => setNameSearch(event.target.value)}
                                    label="Question Name Search"
                                    placeholder="Question Name Search"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <TextField
                                size="small"
                                id="outlined-search"
                                value={search}
                                autoFocus
                                name="search"
                                onChange={(event: any) => setSearch(event.target.value)}
                                label={langString('questiontextsearch')}
                                placeholder={langString('questiontextsearch')}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <Button variant="contained" onClick={() => setOpen(true)}>
                            {langString('autoquizgenerate')}
                        </Button>
                    </Grid>
                    <Modal
                        open={open}
                        onClose={() => setOpen(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box
                            sx={{
                                position: 'absolute' as 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 1000,
                                bgcolor: 'background.paper',
                                border: '2px solid #EEE',
                                boxShadow: 24,
                                padding: 4,
                                borderRadius: 1
                            }}
                        >
                            <AutoGenerateQuiz
                                courseID={courseID}
                                categoryID={categoryID}
                                setOpen={(value) => setOpen(value)}
                                setQuestions={(value) => setSelectedQuestions(value)}
                                setSelectedIDs={(value) => setSelectedIDs(value)}
                                categoryName={categoryName}
                            />
                            <Grid container>
                                <Grid item sm={12}>
                                    <Button sx={{ float: 'right' }} variant="outlined" color="error" onClick={() => setOpen(false)}>
                                        {langString('close')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Modal>
                </Grid>
            </Box>
        </>
    );
}

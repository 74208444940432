import { Grid, Box, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import Wrapper from 'components/Card/Wrapper';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import axiosServices from 'utils/axiosServices';
import { requestUrl, makeFormData } from 'utils/Helpers';
import langString from 'utils/langString';
import QuizIcon from '@mui/icons-material/Quiz';
import { IViewQuizData } from 'types/common';
import PdfViewer from 'components/Pdf';
import PdfPrintButton from 'components/Pdf/PdfPrintButton';
import AnswerSheet from 'components/Pdf/AnswerSheet';
// import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

export default function ViewQuiz() {
    const { quizID } = useParams();
    const divRef = useRef<HTMLDivElement | null>(null);
    const answerDivRef = useRef<HTMLDivElement | null>(null);
    const [quiz, setQuiz] = useState<IViewQuizData | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    useEffect(() => {
        const init = async () => {
            setLoading(true);
            setError('');
            try {
                const response = await axiosServices.post(
                    requestUrl('ace_exam', 'get_quiz_details'),
                    makeFormData([{ key: 'quiz_id', value: quizID! }])
                );
                if (response.status === 200 && response.data.success) {
                    if (response.data?.response) {
                        setQuiz(response.data.response);
                    }
                }
            } catch (e) {
                const axiosError = e as AxiosError;
                setError(axiosError?.message || axiosError?.response?.data?.msg || 'Something went wrong');
            } finally {
                setLoading(false);
            }
        };

        if (quizID) {
            init();
        }
    }, [quizID]);

    return (
        <Wrapper title={langString('quiz')} icon={<QuizIcon />} loading={loading}>
            <Grid container>
                <Grid item sm={12} md={12}>
                    {!error && quiz && (
                        <Box>
                            <Typography variant="h5">Quiz Name: {quiz.details.name}</Typography>
                            <Typography variant="h5">Category Name: {quiz.details.category_name}</Typography>
                            <Typography variant="h5">Course Name: {quiz.details.course_name}</Typography>
                            <Typography variant="h5">
                                Created On: {new Date(+quiz.details.timecreated * 1000).toLocaleDateString()}
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <PdfPrintButton divRef={divRef} name="Print Question" />
                                </Grid>
                                <Grid item>
                                    <PdfPrintButton divRef={answerDivRef} name="Answer Sheet" />
                                </Grid>
                            </Grid>

                            <PdfViewer questions={quiz?.question} divRef={divRef} title={quiz.details.name} />
                            <AnswerSheet questions={quiz?.question} divRef={answerDivRef} title={quiz.details.name} />
                        </Box>
                    )}
                    {error && <div>{error}</div>}
                </Grid>
            </Grid>
        </Wrapper>
    );
}

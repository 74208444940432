import { Tooltip, Button } from '@mui/material';
import ReactToPrint from 'react-to-print';
import langString from 'utils/langString';
import PrintIcon from '@mui/icons-material/Print';

export default function PdfPrintButton({ divRef, name }: { divRef: React.MutableRefObject<HTMLDivElement | null>; name: string }) {
    return (
        <ReactToPrint
            trigger={() => (
                <Tooltip title={langString('download')}>
                    <Button variant="outlined" startIcon={<PrintIcon />}>
                        {name}
                    </Button>
                </Tooltip>
            )}
            content={() => divRef.current}
        />
    );
}

import { useState } from 'react';
import { Checkbox, Card, CardContent, Grid, Typography, Box, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ContentViewer from 'components/common/ContentViewer';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import _ from 'lodash';
import langString from 'utils/langString';

export const borderColor = (value: string) => (parseFloat(value) ? '2px solid #44bd32' : '1px solid #EEE');
export const cleanTextContent = (content: string, limit: number) =>
    limit ? `${content.replace(/(<([^>]+)>)/gi, '').substring(0, limit)}...` : content.replace(/(<([^>]+)>)/gi, '');

type QuestionPropsType = {
    serial: number;
    selected: boolean;
    name: string;
    options: any[];
    description: string;
    slot: 'bank' | 'form' | 'preview';
    actionUpdate: (status: boolean) => void;
    updateSerial?: (from: number, to: number) => void;
    totalQuestions?: number;
};
export default function Question({
    serial,
    selected,
    name,
    options,
    description,
    slot,
    actionUpdate,
    updateSerial,
    totalQuestions
}: QuestionPropsType) {
    const serialItems: number[] = totalQuestions ? _.range(totalQuestions) : [];
    const [open, setOpen] = useState(false);
    return (
        <Card variant="outlined" sx={{ marginBottom: 1 }}>
            <CardContent>
                <Box>
                    <Grid container>
                        {slot === 'bank' && (
                            <Grid item xs={1}>
                                <Checkbox checked={selected} onChange={() => actionUpdate(!selected)} />
                            </Grid>
                        )}
                        {slot !== 'bank' && updateSerial && serialItems.length && (
                            <Grid item xs={2} md={2} xl={1}>
                                <FormControl fullWidth>
                                    <InputLabel id="serial-select-label">{langString('serial')}</InputLabel>
                                    <Select
                                        size="small"
                                        name="serial"
                                        labelId="serial-select-label"
                                        id="serial-select"
                                        value={serial}
                                        label={langString('serial')}
                                        onChange={(event: any) => updateSerial(serial, event.target.value)}
                                    >
                                        {serialItems.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                {item + 1}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}
                        <Grid
                            item
                            xs={slot === 'bank' ? 11 : 9}
                            md={slot === 'bank' ? 11 : 9}
                            xl={10}
                            sx={{
                                img: {
                                    width: '100%',
                                    height: '100%'
                                }
                            }}
                        >
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={() => setOpen(!open)}>
                                    <Typography variant="h6">{name}</Typography>
                                    {!open && (
                                        <Box sx={{ marginLeft: 1 }}>
                                            <ContentViewer content={cleanTextContent(description, 80)} />
                                        </Box>
                                    )}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <ContentViewer content={description} />
                                    <Grid container spacing={2} sx={{ marginTop: 1 }}>
                                        {options &&
                                            options.map((item: any) => (
                                                <Grid key={item.id} item sm={6}>
                                                    <Box
                                                        sx={{
                                                            border: borderColor(item.fraction),
                                                            borderRadius: 3,
                                                            padding: 1.5,
                                                            margin: 0.2,
                                                            overflow: 'auto'
                                                        }}
                                                    >
                                                        <ContentViewer content={item.answer} />
                                                    </Box>
                                                </Grid>
                                            ))}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={1}>
                            {slot !== 'bank' && (
                                <Checkbox
                                    checked={selected}
                                    onChange={() => actionUpdate(!selected)}
                                    checkedIcon={<DisabledByDefaultIcon />}
                                    color="error"
                                    sx={{ zIndex: 0 }}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </CardContent>
        </Card>
    );
}

import { useState } from 'react';
import { Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, Typography, Box, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import { checkNullInfo, makeFormData, requestUrl } from 'utils/Helpers';
import axiosServices from 'utils/axiosServices';
import langString from 'utils/langString';
import SavedQuizesFilter from './Filter';
import Loader from 'ui-component/Loader';
import ConfirmButton from 'components/common/ConfirmButton';
import { CourseType } from 'types/course';
import QuizTableHeader from './Header';
import { useNavigate } from 'react-router-dom';
import QuizPublish, { QuizDeployParamType } from '../QuizPublish';
import PreviewIcon from '@mui/icons-material/Preview';

type QuizesTableProps = {
    topic: string[];
    setTopic: (value: string[]) => void;
    difficulty: number;
    setDifficulty: (value: number) => void;
    search: string;
    setSearch: (value: string) => void;
    quizes: any[];
    setQuizes: (value: any[]) => void;
    deploy: (data: QuizDeployParamType) => void;
    print: (value: number) => void;
    deleteQuiz: (value: number) => void;
    course: CourseType;
};

const quizParams = (courseID: number | string, searchKey: string, difficultyID: number, topics: string[]) =>
    makeFormData([
        { key: 'course_id', value: courseID },
        { key: 'search', value: searchKey },
        { key: 'difficulty', value: difficultyID },
        { key: 'topics', value: topics }
    ]);

export default function QuizesTable({
    deploy,
    print,
    deleteQuiz,
    course,
    topic,
    setTopic,
    difficulty,
    setDifficulty,
    search,
    setSearch,
    quizes,
    setQuizes
}: QuizesTableProps) {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const refreshQuizes = async () => {
        setLoading(true);
        try {
            const response = await axiosServices.post(requestUrl('ace_exam', 'quizes'), quizParams(course?.id, search, difficulty, topic));
            if (response.status === 200 && response.data.success) {
                if (response.data?.response) {
                    setQuizes(response.data.response);
                    setLoading(false);
                }
            }
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    };

    return (
        <Box sx={{ padding: 1 }}>
            {loading && <Loader />}
            <SavedQuizesFilter
                topic={topic}
                setTopic={(values) => setTopic(values)}
                difficulty={difficulty}
                setDifficulty={(value) => setDifficulty(value)}
                search={search}
                setSearch={(value) => setSearch(value)}
                quizes={quizes}
            />
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                    <MainCard sx={{ marginTop: 3, marginBottom: 2 }} content={false}>
                        <TableContainer>
                            <Table sx={{ minWidth: 350 }} aria-label="simple table">
                                <QuizTableHeader />
                                <TableBody>
                                    {quizes.length ? (
                                        quizes.map((row: any, i: number) => (
                                            <TableRow key={i}>
                                                <TableCell align="center">
                                                    <Typography variant="subtitle1" noWrap>
                                                        {i + 1}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="center">{checkNullInfo(row?.details?.name)}</TableCell>
                                                <TableCell align="center">{checkNullInfo(row?.details?.access_code)}</TableCell>
                                                <TableCell align="center">
                                                    {new Date(row?.details?.createdtime * 1000).toLocaleDateString()}
                                                </TableCell>
                                                <TableCell align="center">{checkNullInfo(course?.name)}</TableCell>
                                                <TableCell align="center">
                                                    {row?.details?.topicTag?.length
                                                        ? row?.details?.topicTag.map((top: string, index: number) =>
                                                              index > 0 ? `, ${top}` : top
                                                          )
                                                        : '---'}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row?.details?.difficultyTag?.length
                                                        ? row?.details?.difficultyTag.map((dif: string) => dif.toUpperCase())
                                                        : '---'}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row?.details?.status ? (
                                                        <Typography color="primary">{langString('published')}</Typography>
                                                    ) : (
                                                        <Typography color="error">{langString('draft')}</Typography>
                                                    )}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Typography variant="subtitle1" noWrap>
                                                        <QuizPublish
                                                            quizID={row?.details?.quizid}
                                                            deploy={deploy}
                                                            refreshQuizes={() => refreshQuizes()}
                                                            status={row?.details?.status}
                                                        />
                                                        <Tooltip title={langString('quiz')}>
                                                            <IconButton onClick={() => navigate(`/saved-quizes/${row?.details?.quizid}`)}>
                                                                <PreviewIcon />
                                                            </IconButton>
                                                        </Tooltip>

                                                        <Tooltip title={langString('editquiz')}>
                                                            <IconButton onClick={() => navigate(`/edit-quiz/${row?.details?.quizid}`)}>
                                                                <EditIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <ConfirmButton
                                                            icon={<DeleteForeverIcon />}
                                                            title={langString('deletequiz')}
                                                            subTitle={checkNullInfo(row?.details?.name)}
                                                            confirmed={() => {
                                                                deleteQuiz(row?.details?.quizid);
                                                                setTimeout(() => refreshQuizes(), 2000);
                                                            }}
                                                            color="error"
                                                        />
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell align="center" colSpan={8}>
                                                <Typography>{langString('nodatafound')}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </MainCard>
                </Grid>
            </Grid>
        </Box>
    );
}

import { useEffect, useState } from 'react';
// material-ui
import { Divider, Grid, List, ListItemButton, ListItemIcon, ListItemSecondaryAction, Typography } from '@mui/material';

// project imports
import Avatar from 'ui-component/extended/Avatar';
import SubCard from 'ui-component/cards/SubCard';
import { gridSpacing } from 'store/constant';

// assets
import WcIcon from '@mui/icons-material/Wc';
import MailTwoToneIcon from '@mui/icons-material/MailTwoTone';

import Avatar3 from 'assets/images/users/user-round.svg';
import { checkNullInfo, requestUrl } from 'utils/Helpers';
import axiosServices from 'utils/axiosServices';
import Loader from 'ui-component/Loader';
import ProfileEdit from './Edit';

export default function Summary() {
    const [loading, setLoading] = useState(false);
    const [profile, setProfile] = useState<any>(null);

    const reloadInfo = async () => {
        setLoading(true);
        try {
            const response = await axiosServices.post(requestUrl('user', 'get_user_profile'));
            if (response.data.success) {
                if (response.data?.response) {
                    setProfile(response.data.response);
                }
            }
            setLoading(false);
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    };

    useEffect(() => {
        const init = async () => {
            setLoading(true);
            try {
                const response = await axiosServices.post(requestUrl('user', 'get_user_profile'));
                if (response.data.success) {
                    if (response.data?.response) {
                        setProfile(response.data.response);
                    }
                }
                setLoading(false);
            } catch (e) {
                console.log(e);
                setLoading(false);
            }
        };
        init();
    }, []);

    return (
        <Grid container spacing={gridSpacing}>
            {loading && <Loader />}
            <Grid item lg={4} xs={12}>
                {profile && (
                    <SubCard
                        title={
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    <Avatar alt="User 1" src={profile?.profile_pic ? profile.profile_pic : Avatar3} />
                                </Grid>
                                <Grid item xs zeroMinWidth>
                                    <Typography align="left" variant="subtitle1">
                                        {profile.firstname} {profile.lastname}
                                    </Typography>
                                    <Typography align="left" variant="subtitle2">
                                        {checkNullInfo(profile.username)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        }
                    >
                        <List component="nav" aria-label="main mailbox folders">
                            <ListItemButton>
                                <ListItemIcon>
                                    <MailTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                                </ListItemIcon>
                                <ListItemSecondaryAction>
                                    <Typography variant="body1" align="right">
                                        {checkNullInfo(profile.email)}
                                    </Typography>
                                </ListItemSecondaryAction>
                            </ListItemButton>
                            <Divider />
                            <ListItemButton>
                                <ListItemIcon>
                                    <WcIcon sx={{ fontSize: '1.3rem' }} />
                                </ListItemIcon>
                                <ListItemSecondaryAction>
                                    <Typography variant="body1" align="right">
                                        {checkNullInfo(profile.gender)}
                                    </Typography>
                                </ListItemSecondaryAction>
                            </ListItemButton>
                        </List>
                    </SubCard>
                )}
            </Grid>
            <Grid item lg={8} xs={12}>
                <ProfileEdit profile={profile} updated={() => reloadInfo()} />
            </Grid>
        </Grid>
    );
}

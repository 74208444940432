/* eslint-disable react/no-danger */
import { Box, Grid, Typography } from '@mui/material';
import { MathJax, MathJaxContext } from 'better-react-mathjax';

const config = {
    loader: { load: ['[tex]/html'] },
    tex: {
        packages: { '[+]': ['html'] },
        inlineMath: [
            ['$', '$'],
            ['\\(', '\\)']
        ],
        displayMath: [
            ['$$', '$$'],
            ['\\[', '\\]']
        ]
    }
};
type HtmlRenderTypes = {
    content: string;
    numbering?: string | number;
    concatableString?: string | number;
};
const ContentViewer = ({ content, numbering = '', concatableString = '' }: HtmlRenderTypes) => (
    <Box sx={{ display: 'block' }}>
        <MathJaxContext version={3} config={config}>
            <MathJax hideUntilTypeset="first">
                <>
                    <Grid container spacing={2}>
                        {numbering && (
                            <Grid item>
                                <Typography variant="h4">{numbering}</Typography>
                            </Grid>
                        )}
                        <Grid item paddingLeft={2}>
                            <div
                                style={{ display: 'flex' }}
                                dangerouslySetInnerHTML={{ __html: `${concatableString} <span>${content}</span>` }}
                            />
                        </Grid>
                    </Grid>
                </>
            </MathJax>
        </MathJaxContext>
    </Box>
);
export default ContentViewer;

import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import {
    IconDashboard,
    IconHelp,
    IconUsers,
    IconList,
    IconLayoutGridAdd,
    IconChartLine,
    IconChartArea,
    IconArchive,
    IconPerspective,
    IconUserPlus,
    IconNotification,
    IconHome
} from '@tabler/icons';

// project imports
import NavGroup from './NavGroup';
import axiosServices from 'utils/axiosServices';
import { requestUrl } from 'utils/Helpers';
import { GenericCardProps } from 'types';

type MenuItemType = {
    id: string;
    type: string;
    title: React.ReactNode | string;
    url: string;
    icon?: GenericCardProps['iconPrimary'];
    children?: MenuItemType[];
    caption?: React.ReactNode | string;
    color?: 'primary' | 'secondary' | 'default' | undefined;
};

type MenuGroupType = {
    id?: string;
    type?: string;
    children?: MenuItemType[];
};

const createMenuItems = (data: any[]) => {
    const menuList: MenuItemType[] = [];
    if (data.length) {
        data.forEach((item) => {
            menuList.push({
                id: item.id,
                title: <FormattedMessage id={item.key} />,
                type: item.sub_menu?.length ? 'collapse' : 'item',
                url: item.url ? item.url : '/',
                icon: MenuIcon(item.key),
                children: createMenuItems(item.sub_menu)
            });
        });
    }
    return menuList;
};

const MenuIcon = (key: string) => {
    if (key === 'home') return IconHome;
    if (key === 'dashboard') return IconDashboard;
    if (key === 'batchmanagement') return IconUsers;
    if (key === 'batch') return IconUsers;
    if (key === 'enrolment') return IconLayoutGridAdd;
    if (key === 'quiz') return IconHelp;
    if (key === 'questionbank') return IconArchive;
    if (key === 'quizmaker') return IconArchive;
    if (key === 'savedquizes') return IconPerspective;
    if (key === 'usermanagement') return IconUserPlus;
    if (key === 'reports') return IconChartLine;
    if (key === 'coursereports') return IconChartArea;
    if (key === 'notice') return IconNotification;
    if (key === 'attendence') return IconNotification;

    return IconList;
};

const MenuList = () => {
    const [menuItems, setMenuItems] = useState<MenuGroupType[]>([]);

    useEffect(() => {
        const init = async () => {
            try {
                const response = await axiosServices.post(requestUrl('ace_exam', 'get_sidebar'));
                if (response.data.success) {
                    if (response.data?.response) {
                        const menuData: MenuItemType[] = createMenuItems(response.data.response);
                        setMenuItems([{ id: 'user-acl-sidemenu', type: 'group', children: menuData }]);
                    }
                }
            } catch (e) {
                console.log(e);
            }
        };
        init();
    }, []);

    const navItems = menuItems.map((item: MenuGroupType) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;

// eslint-disable-next-line no-useless-escape
const checkValidEmail = (emailValue: string) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailValue);

const validatePhoneNumber = (phoneNumber: string) => phoneNumber.match(/^[0][1-9]\d{9}$|^[1-9]\d{9}$/g);

const invalidErrorMessage = (item: string, message: string) => {
    const camelCase = item.replace(/([A-Z])/g, ' $1');
    const sentence = camelCase.charAt(0).toUpperCase() + camelCase.slice(1);
    return {
        required: true,
        message: `${sentence} is ${message}`
    };
};

const FormValidators = (fields: string[], values: any[], types: string[] = []) => {
    const errors: any = { valid: true };
    if (fields.length) {
        fields.forEach((item: string, index: number) => {
            if (values[index]) {
                errors[item] = {
                    required: false,
                    message: 'Good'
                };
                if (item === 'email') {
                    if (!checkValidEmail(values[index])) {
                        errors.valid = false;
                        errors[item] = invalidErrorMessage(item, 'invalid');
                    }
                }
                if (item === 'mobile' || item === 'phone') {
                    if (!validatePhoneNumber(values[index])) {
                        errors.valid = false;
                        errors[item] = invalidErrorMessage(item, 'invalid');
                    }
                }
                if (types && types.length) {
                    if (types[index] && types[index] === 'email') {
                        if (!checkValidEmail(values[index])) {
                            errors.valid = false;
                            errors[item] = invalidErrorMessage(item, 'invalid');
                        }
                    }
                    if (types[index] && types[index] === 'phonenumber') {
                        if (!validatePhoneNumber(values[index])) {
                            errors.valid = false;
                            errors[item] = invalidErrorMessage(item, 'invalid');
                        }
                    }
                    if (types[index] && types[index] === 'number') {
                        if (isNaN(values[index])) {
                            errors.valid = false;
                            errors[item] = invalidErrorMessage(item, 'invalid number');
                        }
                    }
                }
            } else {
                errors.valid = false;
                errors[item] = invalidErrorMessage(item, 'required');
            }
        });
    }
    return errors;
};

export default FormValidators;

import { Grid } from '@mui/material';
import OverviewReport from './Overview';

export default function AttendenceReport() {
    return (
        <Grid container>
            <Grid item xs={12}>
                <OverviewReport />
            </Grid>
        </Grid>
    );
}

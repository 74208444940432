/* eslint-disable react/no-danger */
import { Box } from '@mui/material';
import { MathJax, MathJaxContext } from 'better-react-mathjax';
import { answerOption } from 'utils/Helpers';

const config = {
    loader: { load: ['[tex]/html'] },
    tex: {
        packages: { '[+]': ['html'] },
        inlineMath: [
            ['$', '$'],
            ['\\(', '\\)']
        ],
        displayMath: [
            ['$$', '$$'],
            ['\\[', '\\]']
        ]
    }
};
type HtmlRenderTypes = {
    content?: string;
    ansKey: number;
};
const ContentViewerAns = ({ content, ansKey }: HtmlRenderTypes) => (
    <Box sx={{ display: 'block' }}>
        <MathJaxContext version={3} config={config}>
            {content ? (
                <MathJax hideUntilTypeset="first">
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                </MathJax>
            ) : (
                <MathJax hideUntilTypeset="first">{` (${answerOption(ansKey)})`}</MathJax>
            )}
        </MathJaxContext>
    </Box>
);
export default ContentViewerAns;

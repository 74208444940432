import { useState, useRef } from 'react';
import _ from 'lodash';

import { Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import langString from 'utils/langString';

const hours = _.range(1, 25, 1);
const minutes = _.range(1, 61, 1);

type DateTimePickerPropsType = {
    setDateTime: (ymd: string) => void;
};
export default function DateTimePicker({ setDateTime }: DateTimePickerPropsType) {
    // const [day, setDay] = useState(1);
    const today = new Date();
    const day = useRef(Number(String(today.getDate()).padStart(2, '0')));
    const month = useRef(Number(String(today.getMonth() + 1).padStart(2, '0')));
    const year = useRef(Number(today.getFullYear()));
    const hour = useRef(today.getHours());
    const minute = useRef(today.getMinutes());
    const [days, setDays] = useState<number[]>(_.range(1, 32, 1));

    const changeMonth = (value: number) => {
        month.current = value;
        if (value === 2) {
            setDays(_.range(1, 30, 1));
        } else if ([9, 4, 6, 11].includes(value)) {
            setDays(_.range(1, 31, 1));
        } else {
            setDays(_.range(1, 32, 1));
        }
    };
    const months = _.range(1, 13, 1);
    const years = _.range(1950, 2040, 1);

    const getMonthName = (mon: number) => {
        const d = new Date(2018, 4, 3, 21, 0);

        d.setMonth(mon - 1);

        const monName = d.toLocaleString('default', { month: 'long' });

        return monName;
    };
    // const setTime = () => {
    //     console.log(year, month, day, hour, minute);
    //     setDateTime(`${year}-${month}-${day} ${hour}:${minute}`);
    // };
    const changeDateTime = (element: string, value: number) => {
        if (element === 'year') {
            year.current = value;
        }
        if (element === 'month') {
            month.current = value;
            changeMonth(value);
        }
        if (element === 'day') {
            // setDay(value);
            day.current = value;
            // console.log('day', day.current);
        }
        if (element === 'hour') {
            hour.current = value;
        }
        if (element === 'minute') {
            minute.current = value;
        }
        setDateTime(`${year.current}-${month.current}-${day.current} ${hour.current}:${minute.current}`);
    };

    return (
        <Grid container spacing={1}>
            <Grid item>
                <FormControl fullWidth>
                    <InputLabel id="year-select-label">{langString('year')}</InputLabel>
                    <Select
                        size="small"
                        labelId="year-select-label"
                        id="year-select"
                        value={year.current}
                        label="Year"
                        onChange={(event: any) => changeDateTime('year', event.target.value)}
                    >
                        {years.map((item: number) => (
                            <MenuItem key={item} value={item}>
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item>
                <FormControl fullWidth>
                    <InputLabel id="month-select-label">{langString('month')}</InputLabel>
                    <Select
                        size="small"
                        labelId="month-select-label"
                        id="month-select"
                        value={month.current}
                        label="Month"
                        onChange={(event: any) => changeDateTime('month', event.target.value)}
                    >
                        {months.map((item: number) => (
                            <MenuItem key={item} value={item}>
                                {getMonthName(item)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item>
                <FormControl fullWidth>
                    <InputLabel id="day-select-label">{langString('day')}</InputLabel>
                    <Select
                        size="small"
                        labelId="day-select-label"
                        id="day-select"
                        value={day.current}
                        label="Day"
                        onChange={(event: any) => changeDateTime('day', event.target.value)}
                    >
                        {days.length &&
                            days.map((item: number) => (
                                <MenuItem key={item} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item>
                <FormControl fullWidth>
                    <InputLabel id="hour-select-label">{langString('hour')}</InputLabel>
                    <Select
                        size="small"
                        labelId="hour-select-label"
                        id="hour-select"
                        value={hour.current}
                        label="Hour"
                        onChange={(event: any) => changeDateTime('hour', event.target.value)}
                    >
                        {hours.length &&
                            hours.map((item: number) => (
                                <MenuItem key={item} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item>
                <FormControl fullWidth>
                    <InputLabel id="minute-select-label">{langString('minute')}</InputLabel>
                    <Select
                        size="small"
                        labelId="minute-select-label"
                        id="minute-select"
                        value={minute.current}
                        label="Minute"
                        onChange={(event: any) => changeDateTime('minute', event.target.value)}
                    >
                        {minutes.length &&
                            minutes.map((item: number) => (
                                <MenuItem key={item} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
}

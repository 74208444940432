import { useEffect, useState } from 'react';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import Wrapper from 'components/Card/Wrapper';
import langString from 'utils/langString';
import axiosServices from 'utils/axiosServices';
import { SnackBarType } from 'types/common';
import { makeFormData, requestUrl } from 'utils/Helpers';
import SelectTable, { SelectColumnType } from 'components/Table/SelectTable';
import { useSelector } from 'react-redux';
import { DefaultRootStateProps } from 'types';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import Chip from 'ui-component/extended/Chip';

export default function Attendence() {
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState<SnackBarType>({ open: false, message: '' });
    const [students, setStudents] = useState<any[]>([]);
    const [batches, setBatches] = useState<any[]>([]);
    const [batchID, setBatchID] = useState(0);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [search, setSearch] = useState('');
    const [checkAll, setCheckAll] = useState(false);
    const [selectedStudents, setSelectedStudents] = useState<number[]>([]);
    const course = useSelector((state: DefaultRootStateProps) => state.courseData);
    const [dateValue, setDateValue] = useState<Dayjs>(dayjs());

    const isWeekend = (date: Dayjs) => {
        const day = date.day();
        // return day === 0 || day === 6;
        return day === null;
    };

    const StudentColumns: SelectColumnType[] = [
        {
            header: langString('name'),
            accessor: 'name'
        },
        {
            header: langString('email'),
            accessor: 'email'
        },
        {
            header: langString('phone'),
            accessor: 'phone'
        },
        {
            header: 'Status',
            accessor: 'action',
            content: (item: any) => (
                <Typography variant="overline" gutterBottom>
                    {item.attendenceStatus === 1 ? (
                        <Chip label={langString('present')} chipcolor="success" onClick={() => changeAttendenceStatus(item.userid)} />
                    ) : (
                        <Chip label={langString('absent')} chipcolor="error" onClick={() => changeAttendenceStatus(item.userid)} />
                    )}
                </Typography>
            )
        }
    ];

    const changeAttendenceStatus = async (userId: number) => {
        setLoading(true);
        try {
            const response = await axiosServices.post(
                requestUrl('ace_exam', 'single_attendence'),
                makeFormData([
                    { key: 'user_id', value: userId },
                    { key: 'batch_id', value: 31 },
                    { key: 'date', value: dayjs(dateValue).format('MM/DD/YYYY') }
                ])
            );
            if (response.data.success) {
                const i = students.findIndex((student: any) => student.userid === userId);
                if (students[i]) {
                    students[i].attendenceStatus = students[i].attendenceStatus === 1 ? 0 : 1;
                }
                setStudents([...students]);
                setSnackbar({ open: true, message: response.data.msg, alert: 'success' });
                setLoading(false);
            }
        } catch (e) {
            console.log(e);
            setLoading(false);
            setSnackbar({ open: true, message: langString('servererror') });
        }
    };

    const selectAllStudents = (value: boolean) => {
        if (value) {
            setCheckAll(true);
            if (students) {
                const allStudents: number[] = [];
                students.forEach((st) => allStudents.push(st.userid));
                setSelectedStudents(allStudents);
            }
        } else {
            setCheckAll(false);
            setSelectedStudents([]);
        }
    };

    const selectSingleStudent = (studentID: number) => {
        const allSelectedStudents = [...selectedStudents];
        if (allSelectedStudents.includes(studentID)) {
            const reduced = allSelectedStudents.filter((item) => item !== studentID);
            setSelectedStudents(reduced);
        } else {
            allSelectedStudents.push(studentID);
            setSelectedStudents(allSelectedStudents);
        }
    };

    useEffect(() => {
        const init = async () => {
            setLoading(true);
            try {
                const response = await axiosServices.post(
                    requestUrl('ace_exam', 'get_batches'),
                    makeFormData([{ key: 'course_id', value: course?.id }])
                );
                if (response.data.success) {
                    if (response.data?.response && response.data?.response?.length) {
                        setBatches(response.data.response);
                        setBatchID(response.data.response[0].id);
                    } else {
                        setSnackbar({ open: true, message: langString('nobatchavailable') });
                    }
                    setLoading(false);
                }
            } catch (e) {
                console.log(e);
                setLoading(false);
                setSnackbar({ open: true, message: langString('servererror') });
            }
        };
        if (course) {
            init();
        } else {
            setSnackbar({ open: true, message: 'Course not selected' });
        }
    }, [course]);

    useEffect(() => {
        const init = async () => {
            setLoading(true);
            try {
                const response = await axiosServices.post(
                    requestUrl('ace_exam', 'get_students_attendence'),
                    makeFormData([
                        { key: 'course_id', value: course?.id },
                        { key: 'batch_id', value: batchID },
                        { key: 'search', value: search },
                        { key: 'date', value: dayjs(dateValue).format('MM/DD/YYYY') },
                        { key: 'rows_per_page', value: rowsPerPage },
                        { key: 'page_no', value: page }
                    ])
                );
                if (response.data.success) {
                    if (response.data?.response?.data) {
                        setStudents(response.data.response?.data);
                        setCount(response.data.response?.totalCount);
                    }
                    setLoading(false);
                }
            } catch (e) {
                console.log(e);
                setLoading(false);
                setSnackbar({ open: true, message: langString('servererror') });
            }
        };
        if (course && batchID) {
            init();
        }
    }, [course, dateValue, batchID, search, rowsPerPage, page]);

    return (
        <Wrapper
            title={langString('attendence')}
            icon={<GroupAddIcon />}
            snakbar={snackbar}
            snackbarClose={() => setSnackbar({ open: false, message: '' })}
            loading={loading}
        >
            <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                <Grid item sm={4}>
                    <FormControl fullWidth sx={{ paddingRight: 1 }}>
                        <InputLabel id="batch-select-label">{langString('batch')}</InputLabel>
                        <Select
                            size="small"
                            name="batchs"
                            labelId="batch-select-label"
                            id="batch-select"
                            value={batchID}
                            label={langString('batch')}
                            onChange={(event: any) => setBatchID(event.target.value)}
                        >
                            {batches.map((item: any) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sm={4}>
                    <TextField
                        id="outlined-search"
                        value={search}
                        name="search"
                        size="small"
                        onChange={(event: any) => setSearch(event.target.value)}
                        label={langString('search')}
                        placeholder={langString('search')}
                    />
                </Grid>
                <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Select date"
                            value={dateValue}
                            onChange={(newValue) => {
                                newValue && setDateValue(newValue);
                            }}
                            shouldDisableDate={isWeekend}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item>{selectedStudents.length > 0 && <Button variant="contained">Click to submit selected attendence</Button>}</Grid>
            </Grid>
            <Grid container>
                <Grid item sm={12}>
                    <SelectTable
                        idField="userid"
                        rows={students}
                        columns={StudentColumns}
                        showSL
                        count={count}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        setPage={(value: number) => setPage(value)}
                        setRowsPerPage={(value: number) => setRowsPerPage(value)}
                        selectedFields={selectedStudents}
                        checkAll={checkAll}
                        changeCheckAll={(value: boolean) => selectAllStudents(value)}
                        checkUpdate={(id: number) => selectSingleStudent(id)}
                    />
                </Grid>
            </Grid>
        </Wrapper>
    );
}

// project imports
import MainLayout from 'layout/MainLayout';
import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import BatchList from 'views/Batch';
import AddStudent from 'views/Batch/AddStudent';
import BatchEnrolment from 'views/Batch/Enrolment';
import BatchStudents from 'views/Batch/Students';
import Profile from 'views/Profile';
import QuestionBank from 'views/Quiz/QuestionBank';
import SavedQuizes from 'views/Quiz/SavedQuizes';
import QuizReports from 'views/Reports/Quizes';
import ReportParticipants from 'views/Reports/Participants';
import ProfileReport from 'views/Reports/Profile';
import EnrolmentRequests from 'views/UserManagement/EnrolmentRequests';
import StudentReports from 'views/Reports/Students';
import GeneralNotice from 'views/Notice/GeneralNotice';
import Packages from 'views/Packages';
import Payments from 'views/Payments';
import Dashboard from 'views/Dashboard';
import DashboardStudentProfile from 'views/Dashboard/StudentProfile';
import ViewQuiz from 'views/Quiz/SavedQuizes/View';
import Attendence from 'views/Attendence';
import AttendenceReport from 'views/Attendence/Report';

// sample page routing
const Home = Loadable(lazy(() => import('views/Home')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <Home />
        },
        {
            path: '/dashboard',
            element: <Dashboard />
        },
        {
            path: '/student-profile/:studentID',
            element: <DashboardStudentProfile />
        },
        {
            path: '/batch',
            element: <BatchList />
        },
        {
            path: '/enrolment',
            element: <BatchEnrolment />
        },
        {
            path: '/batch-students/:batch_id',
            element: <BatchStudents />
        },
        {
            path: 'question-bank',
            element: <QuestionBank />
        },
        {
            path: 'edit-quiz/:quizID',
            element: <QuestionBank />
        },
        {
            path: 'saved-quizes',
            element: <SavedQuizes />
        },
        {
            path: 'add-student',
            element: <AddStudent />
        },
        {
            path: 'enrolment-requests',
            element: <EnrolmentRequests />
        },
        {
            path: 'quiz-reports',
            element: <QuizReports />
        },
        {
            path: 'student-reports',
            element: <StudentReports />
        },
        {
            path: 'student-report/:studentID',
            element: <ProfileReport />
        },
        {
            path: 'saved-quizes/:quizID',
            element: <ViewQuiz />
        },
        {
            path: 'quiz-participants/:quizID',
            element: <ReportParticipants />
        },
        {
            path: 'general-notice',
            element: <GeneralNotice />
        },
        {
            path: 'packages',
            element: <Packages />
        },
        {
            path: 'payments',
            element: <Payments />
        },
        {
            path: '/profile',
            element: <Profile />
        },
        {
            path: '/attendence',
            element: <Attendence />
        },
        {
            path: '/attendence-report',
            element: <AttendenceReport />
        }
    ]
};

export default MainRoutes;
